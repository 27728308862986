import { reactive } from "vue";

import { Helpers, Format, ObjectLib } from "@/core/utils";

import { Commercials } from "@/core/commercials";

let $_TableObject =
{
    accountMenu:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups  : [ { id: 'column', label: 'Column' } ],
            columns : [ { id: 'default', group: 'column', label: 'Default' } ],
            presets : [ { id: 'default', name: 'default', columns: [ 'default' ] } ]
        }
    },

    companyMenu:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups  : [ { id: 'column', label: 'Column' } ],
            columns : [ { id: 'default', group: 'column', label: 'Default' } ],
            presets : [ { id: 'default', name: 'default', columns: [ 'default' ] } ]
        }
    },

    userHierarchy:
    {
        grid    : {},
        table   :
        {
            groups  :
            [
                { id: 'user', label: 'User' }
            ],
            columns :
            [
                {
                    id     : 'title', group: 'user', label: 'User',
                    width  : '384px',
                    scope  : [ 'admin', 'employer', 'recruiter' ],
                    sort   : { asc: 'title_asc', desc: 'title_desc' },
                    export :
                    {
                        columns: []
                    }
                },
            ],
            presets:
            [
                { id: 'admin', name: 'default', columns: [ "title" ] },
                { id: 'employer', name: 'default', columns: [ "title" ] },
                { id: 'recruiter', name: 'default', columns: [ "title" ] },
            ]
        }
    },

    mailboxList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            options:
            {
                hideThead: true
            },

            groups:
            [
                { id: 'message', label: 'Message' },
            ],

            columns:
            [
                { id: 'message', group: 'message', label: 'Message', width: 'auto' }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'message' ] }
            ]
        }
    },

    livechatList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            options:
            {
                hideThead: true
            },

            groups:
            [
                { id: 'message', label: 'Message' },
            ],

            columns:
            [
                { id: 'message', group: 'message', label: 'Message', width: 'auto' }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'message' ] }
            ]
        }
    },

    roleList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'role', label: 'Role' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'role', label: 'Role name',
                    tooltip : { title: 'Role name', description: 'The name of the role' },
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'description', group: 'role', label: 'Role description',
                    tooltip : { title: 'Role description', description: 'The description of the role' },
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'description' ] }
            ]
        }
    },

    personList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'person', label: 'Person' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'person', label: 'Person',
                    tooltip : { title: 'Person', description: 'The name of the person' },
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title' ] }
            ]
        }
    },

    accountList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'account', label: 'Account' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'recruitment', label: 'Agency user',
                    tooltip : { title: 'Agency user', description: 'The name of the agency user' },
                    width   : '384px',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'fill_rate', group: 'recruitment', label: 'Avg. fill rate',
                    tooltip : { title: 'Avg. fill rate', description: 'The average fill rate of the agency user' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'jobs', group: 'recruitment', label: 'Jobs',
                    tooltip : { title: 'Jobs', description: 'The total number of jobs posted by the agency user' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'applications', group: 'recruitment', label: 'Applications',
                    tooltip : { title: 'Applications', description: 'The total number of applications received by the agency user' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'placements', group: 'recruitment', label: 'Placements',
                    tooltip : { title: 'Placements', description: 'The total number of placements made by the agency user' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'contracts', group: 'recruitment', label: 'Contractors',
                    tooltip : { title: 'Contractors', description: 'The total number of contractors hired by the agency user' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'fill_rate', 'jobs', 'applications', 'placements', 'contracts' ] }
            ]
        }
    },

    onboardingList:
    {
        grid    : { columns : 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'onboarding', label: 'Onboarding' }
            ],

            columns:
            [
                {
                    id      : 'title', group: 'onboarding', label: 'Agency',
                    tooltip : { title: 'Agency', description: 'The name of the agency' },
                    width   : '384px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'status', group: 'onboarding', label: 'Status',
                    tooltip : { title: 'Status', description: 'The status of the onboarding' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'status_asc', desc: 'status_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'type', group: 'onboarding', label: 'Onboarding type',
                    tooltip : { title: 'Onboarding type', description: 'The type of the onboarding' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruiter_asc', desc: 'recruiter_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'inviter', group: 'onboarding', label: 'Inviter',
                    tooltip : { title: 'Inviter', description: 'The name of the person who invited the agency' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'inviter_asc', desc: 'inviter_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'created', group: 'onboarding', label: 'Created',
                    tooltip : { title: 'Created', description: 'The date when the onboarding was created' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns: []
                    }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ "title", "status", "type", "inviter", "created" ] },
                { id: 'employer', name: 'default', columns: [ "title", "status", "type", "inviter", "created" ] },
                { id: 'recruiter', name: 'default', columns: [ "title", "status", "type", "inviter", "created" ] },
            ]
        }
    },

    programmeList: //DONE
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'programme', label: 'Program' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'programme', label: 'Program title',
                    tooltip : { title: 'Program title', description: 'The title of the program' },
                    width   : '384px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' }
                },
                {
                    id      : 'status', group: 'programme', label: 'Onboarding status',
                    tooltip : { title: 'Onboarding status', description: 'The status of the onboarding' },
                    align   : 'center',
                    scope   : [ 'recruiter' ],
                    //sort  : { asc: 'agencyRate_asc', desc: 'agencyRate_desc' }
                },
                /*
                {
                    id      : 'rate', group: 'programme', label: 'Avg. agency rate',
                    tooltip : { title: 'Avg. agency rate', description: 'The average agency rate of the program' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort  : { asc: 'agencyRate_asc', desc: 'agencyRate_desc' }
                },
                */
                {
                    id      : 'agencies', group: 'programme', label: 'Agencies',
                    tooltip : { title: 'Agencies', description: 'The total number of agencies onboarded to the program' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort  : { asc: 'agenciesTotal_asc', desc: 'agenciesTotal_desc' }
                },
                {
                    id      : 'jobs', group: 'programme', label: 'Job requisitions',
                    tooltip : { title: 'Job requisitions', description: 'The total number of job requisitions posted to the program' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort  : { asc: 'agenciesTotal_asc', desc: 'agenciesTotal_desc' }
                },
                {
                    id      : 'applications', group: 'programme', label: 'Applications',
                    tooltip : { title: 'Applications', description: 'The total number of applications received by the program' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort  : { asc: 'applicationsTotal_asc', desc: 'applicationsTotal_desc' }
                },
                {
                    id      : 'placements', group: 'programme', label: 'Placements',
                    tooltip : { title: 'Placements', description: 'The total number of placements made by the program' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort  : { asc: 'placementsTotal_asc', desc: 'placementsTotal_desc' }
                },
                {
                    id      : 'contracts', group: 'programme', label: 'Contractors',
                    tooltip : { title: 'Contractors', description: 'The total number of contractors hired by the program' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort  : { asc: 'contractsTotal_asc', desc: 'contractsTotal_desc' }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', /*'rate',*/ 'agencies', 'jobs', 'applications', 'placements', 'contracts' ] },
                { id: 'employer', name: 'default', columns: [ 'title', /*'rate',*/ 'agencies', 'jobs', 'applications', 'placements', 'contracts' ] },
                { id: 'recruiter', name: 'default', columns: [ 'title', 'status', 'jobs', 'applications', 'placements', 'contracts' ] },
            ]
        }
    },

    programmeLocationList: //DONE
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'programmeLocation', label: 'Program location' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'programmeLocation', label: 'Title',
                    tooltip : { title: 'Title', description: 'The title of the program location' },
                    width   : '384px',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'jobs', group: 'programmeLocation', label: 'Total jobs',
                    tooltip : { title: 'Total jobs', description: 'The total number of jobs posted to the program location' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'placements', group: 'programmeLocation', label: 'Total placements',
                    tooltip : { title: 'Total placements', description: 'The total number of placements made by the program location' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'contractors', group: 'programmeLocation', label: 'Total contractors',
                    tooltip : { title: 'Total contractors', description: 'The total number of contractors hired by the program location' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'jobs', 'placements', 'contractors' ] }
            ]
        }
    },

    agencyList: //DONE
    {
        grid  : { columns: 4, gap: '24px' },
        table :
        {
            groups:
            [
                { id: 'agency', label: 'Agency' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'agency', label: 'Agency name',
                    tooltip : { title: 'Agency name', description: 'The name of the agency, along with its operational region (e.g., local, regional, or global).' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'title', title: 'Agency name', data: ( item, ctx ) => { return item?.name } },
                            { id: 'region_level', title: 'Region level', data: ( item, ctx ) => { return item?.summary?.regionLevel } },
                        ]
                    }
                },
                {
                    id      : 'tags', group: 'agency', label: 'Tags',
                    tooltip : { title: 'Tags', description: 'The tags associated with the agency.' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'tags', title: 'Tags', data: ( item, ctx ) => { return item?.tags?.map( t => t.name )?.join(', ') } }
                        ]
                    }
                },
                {
                    id      : 'completeness', group: 'agency', label: 'Profile completeness',
                    tooltip : { title: 'Profile completeness', description: 'The percentage of the agency profile that has been completed.' },
                    width   : '256px',
                    scope   : [ 'admin' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'completeness', title: 'Profile completeness', data: ( item, ctx ) => { return item?.completeness } },
                        ]
                    }
                },
                {
                    id      : 'onboarded', group: 'agency', label: 'Onboarded programs',
                    tooltip : { title: 'Onboarded programs', description: 'The total number of programs the agency is onboarded to.' },
                    align   : 'center',
                    scope   : [ 'admin' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'onboarded', title: 'Onboarded programs', data: ( item, ctx ) => { return item.onboardings.length } },
                        ]
                    }
                },
                {
                    id      : 'social', group: 'agency', label: 'Social',
                    tooltip : { title: 'Social', description: 'The social media links of the agency.' },
                    align   : 'center',
                    scope   : [ 'admin' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'website', title: 'Website', data: ( item, ctx ) => { return item?.contact?.website } },
                            { id: 'linkedin', title: 'Linkedin', data: ( item, ctx ) => { return item?.contact?.externalURLs?.linkedin } }
                        ]
                    }
                },
                {
                    id      : 'coc_signed', group: 'agency', label: 'Code of conducts',
                    tooltip : { title: 'Code of Conducts', description: 'The status of the code of conducts signed by the agency.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'coc_asc', desc: 'coc_desc' },
                    export :
                    {
                        columns:
                        [
                            { id: 'coc_signed', title: 'Code of Conducts', data: ( item, ctx ) => { return item?.summary?.CoCSigned === 'Yes' ? 'Available' : 'Unknown' } }
                        ]
                    }
                },
                {
                    id      : 'location', group: 'agency', label: 'Location based',
                    tooltip : { title: 'Location based', description: 'The location where is agency based.' },
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'locations_asc', desc: 'locations_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'location', title: 'Location based', data: ( item, ctx ) => { return item?.location?.name } }
                        ]
                    }
                },
                {
                    id      : 'most_placements', group: 'agency', label: 'Category with most placements',
                    tooltip : { title: 'Category with most placements', description: 'The job category where the agency has made the most placements, indicating its strongest area of performance.' },
                    scope   : [ 'admin', 'employer' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'most_placements', title: 'Category with most placements', data: ( item, ctx ) => { return item?.summary?.topCategory?.label } }
                        ]
                    }
                },
                /*
                {
                    id      : 'agency_fee', group: 'agency', label: 'Agency fee in %',
                    tooltip : { title: 'Agency fee in %', description: 'The average agency fee in percentage.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'agencyFee_asc', desc: 'agencyFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'agency_fee', title: 'Agency fee in %', data: ( item, ctx ) => { return item?.summary?.avgAgencyFee } }
                        ]
                    }
                },
                */
                {
                    id      : 'fill_rate', group: 'agency', label: 'Average fill rate',
                    tooltip : { title: 'Average fill rate', description: 'The average fill rate of the agency.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'fillRate_asc', desc: 'fillRate_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'fill_rate', title: 'Average fill rate in %', data: ( item, ctx ) => { return item?.summary?.avgFillRate } }
                        ]
                    }
                },
                {
                    id      : 'recruiters_onboarded', group: 'agency', label: 'Recruiters onboarded',
                    tooltip : { title: 'Recruiters onboarded', description: 'The total number of recruiters onboarded to agency.' },
                    align   : 'center',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'recruitersTotal_asc', desc: 'recruitersTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'recruiters_onboarded', title: 'Recruiters onboarded', data: ( item, ctx ) => { return item?.summary?.recruiters } }
                        ]
                    }
                },
                {
                    id      : 'jobs_total', group: 'agency', label: 'Job requisitions total',
                    tooltip : { title: 'Jobs total', description: 'The total number of job requisitions in which the agency has been involved.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'jobsTotal_asc', desc: 'jobsTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'jobs_total', title: 'Job requisitions total', data: ( item, ctx ) => { return item?.summary?.jobs } }
                        ]
                    }
                },
                {
                    id      : 'applications_total', group: 'agency', label: 'Applications total',
                    tooltip : { title: 'Applications total', description: 'The total number of applications submitted by agency.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'applicationsTotal_asc', desc: 'applicationsTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'applications_total', title: 'Applications total', data: ( item, ctx ) => { return item?.summary?.applications } }
                        ]
                    }
                },
                {
                    id      : 'placements_total', group: 'agency', label: 'Placements total',
                    tooltip : { title: 'Placements total', description: 'The total number of placements made by the agency.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'placementsTotal_asc', desc: 'placementsTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'placements_total', title: 'Placements total', data: ( item, ctx ) => { return item?.summary?.placements } }
                        ]
                    }
                },
                {
                    id      : 'contractors_total', group: 'agency', label: 'Contractors total',
                    tooltip : { title: 'Contractors total', description: 'The total number of contractors hired by the agency.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'contractorsTotal_asc', desc: 'contractorsTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'contractors_total', title: 'Contractors total', data: ( item, ctx ) => { return item?.summary?.contractors } }
                        ]
                    }
                },
                {
                    id      : 'created', group: 'agency', label: 'Created',
                    tooltip : { title: 'Created', description: 'The date when the agency was created on platform.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created', title: 'Created', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'admin', name: 'default', columns: [ "title", "tags", "completeness", "most_placements", "location", "onboarded", "jobs_total", "placements_total", "contractors_total" ] },
                { id: 'employer', name: 'default', columns: [ "title", "tags", /*"completeness", "agency_fee",*/ "fill_rate", "placements_total", "contractors_total" ] }
            ]
        }
    },

    agencyOnboardingList:
    {
        grid    : { columns : 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'agencyOnboarding', label: 'Agency program' }
            ],

            columns:
            [
                {
                    id      : 'title', group: 'agencyOnboarding', label: 'Program',
                    tooltip : { title: 'Agency', description: 'The name of the program' },
                    width   : '384px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'status', group: 'agencyOnboarding', label: 'Status',
                    tooltip : { title: 'Status', description: 'The status of the onboarding' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'status_asc', desc: 'status_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'type', group: 'agencyOnboarding', label: 'Onboarding type',
                    tooltip : { title: 'Onboarding type', description: 'The type of the onboarding' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruiter_asc', desc: 'recruiter_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'inviter', group: 'agencyOnboarding', label: 'Inviter',
                    tooltip : { title: 'Inviter', description: 'The name of the person who invited the agency' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'inviter_asc', desc: 'inviter_desc' },
                    export  :
                    {
                        columns: []
                    }
                },
                {
                    id      : 'created', group: 'agencyOnboarding', label: 'Created',
                    tooltip : { title: 'Created', description: 'The date when the onboarding was created' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns: []
                    }
                }
            ],

            presets:
            [
                { id: 'admin', name: 'default', columns: [ "title", "status", "type", "inviter", "created" ] },
                { id: 'employer', name: 'default', columns: [ "title", "status", "type", "inviter", "created" ] },
                { id: 'recruiter', name: 'default', columns: [ "title", "status", "inviter", "created" ] },
            ]
        }
    },

    jobList: //DONE
    {
        grid    : { columns : 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'job', label: 'Job' },
            ],

            columns:
            [
                {
                    id      : 'open_jobs', group: 'job', label: 'Open jobs',
                    tooltip : { title: 'Open jobs', description: 'The total number of available positions that are open for this job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'openJobsTotal_asc', desc: 'openJobsTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'open_jobs', title: 'Open jobs', data: ( item, ctx ) => { return item?.summary?.positions?.open } }
                        ]
                    }
                },
                {
                    id      : 'title', group: 'job', label: 'Job requisition',
                    tooltip : { title: 'Job requisition', description: 'Displays the job requisition status, title and contract type. The job requisition status indicates if the position is active, closed, or on hold.' },
                    width   : '384px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'reference', title: 'Reference', data: ( item, ctx ) => { return item?.reference } },
                            { id: 'status', title: 'Status', data: ( item, ctx ) => { return item?.status } },
                            { id: 'title', title: 'Title', data: ( item, ctx ) => { return item?.title } },
                            { id: 'contractType', title: 'Contract type', data: ( item, ctx ) => { return item?.employment?.type } },
                        ]
                    }
                },
                {
                    id      : 'reference', group: 'job', label: 'Reference',
                    tooltip : { title: 'Reference', description: 'Shows the employer’s internal reference or unique ID for the job posting. This number is used for tracking and management purposes.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'reference_asc', desc: 'reference_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'reference', title: 'Reference', data: ( item, ctx ) => { return item?.reference } }
                        ]
                    }
                },
                {
                    id      : 'stage', group: 'job', label: 'Stage',
                    tooltip : { title: 'Stage', description: `Reflects the current phase of the hiring process for this job.
                            <br/>
                            <br/>
                            <table>
                                <tr><td><b>Posted:</b></td> <td>Job is live and open for applications.</td></tr>
                                <tr><td><b>Engaged:</b></td> <td>Agencies have been engaged to recruit for this position.</td></tr>
                                <tr><td><b>Application:</b></td> <td>Applications have been received and are under review.</td></tr>
                                <tr><td><b>Offer:</b></td> <td>A candidate has been offered the position.</td></tr>
                            </table>
                            <br/>
                            <i>Hover over any stage to see more details about what it entails.</i>
                    ` },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'stage_asc', desc: 'stage_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'stage', title: 'Stage', data: ( item, ctx ) => { return item?.summary?.stage } }
                        ]
                    }
                },
                {
                    id      : 'job_closing_reason', group: 'job', label: 'Job closing reason',
                    tooltip : { title: 'Job closing reason', description: 'The reason why the job was closed.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'job_closing_reason', title: 'Job closing reason', data: ( item, ctx ) => { return item?.closeReason } }
                        ]
                    }
                },
                {
                    id      : 'category', group: 'job', label: 'Category',
                    tooltip : { title: 'Category', description: 'Lists the field or industry the job belongs to, such as IT, Business, Healthcare, etc. It helps categorize the job for better filtering and searching.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'category_asc', desc: 'category_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'category', title: 'Category', data: ( item, ctx ) => { return item?.category?.label } }
                        ]
                    }
                },
                {
                    id      : 'skills', group: 'job', label: 'Skills',
                    tooltip : { title: 'Skills', description: 'Displays the required and recommended skills that candidates should have to be considered for the job. These may include technical, soft, or industry-specific skills.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'skills_asc', desc: 'skills_desc' },
                    export :
                    {
                        columns:
                        [
                            { id: 'skills', title: 'Skills', data: ( item, ctx ) => { return item?.skills?.map( s => s.label )?.join(', ') } }
                        ]
                    }
                },
                {
                    id      : 'location', group: 'job', label: 'Location',
                    tooltip : { title: 'Location', description: 'Indicates country where the position is based.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'locations_asc', desc: 'locations_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'location', title: 'Location', data: ( item, ctx ) => { return item?.locations?.map( s => s.name )?.join(', ') } }
                        ]
                    }
                },
                {
                    id      : 'remote_work', group: 'job', label: 'Remote work',
                    tooltip : { title: 'Remote work', description: 'Shows whether the job allows for remote work. If “Yes,” the position can be done from anywhere, or with flexible work-from-home options.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'remoteWork_asc', desc: 'remoteWork_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'remote_work', title: 'Remote work', data: ( item, ctx ) => { return item?.remote ? 'Yes' : 'No' } }
                        ]
                    }
                },
                {
                    id      : 'confidential_role', group: 'job', label: 'Confidential role',
                    tooltip : { title: 'Confidential role', description: 'When the employer wants to be anonymous until a chosen time with the finalist or shortlist candidates.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'confidentialRole_asc', desc: 'confidentialRole_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'confidential_role', title: 'Confidential role', data: ( item, ctx ) => { return item?.confidential ? 'Yes' : 'No' } }
                        ]
                    }
                },
                {
                    id      : 'programme', group: 'job', label: 'Program',
                    tooltip : { title: 'Program', description: 'A program refers to an agreed setup for the job, including key commercial details such as the recruitment fee, rebate policy, and other related terms.' },
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'programme_asc', desc: 'programme_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'programme', title: 'Program', data: ( item, ctx ) => { return item?.programme?.title } },
                            { id: 'programme_location', title: 'Program location', data: ( item, ctx ) => { return item?.programmeLocation?.title } },
                        ]
                    }
                },
                {
                    id      : 'employer', group: 'job', label: 'Job manager',
                    tooltip : { title: 'Job manager', description: 'The name of the user who submitted the job posting, along with their company title.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'employer_asc', desc: 'employer_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'employer', title: 'Job manager', data: ( item, ctx ) => { return item?.employers?.[0]?.name } },
                            { id: 'employer_organization', title: 'Job manager organization', data: ( item, ctx ) => { return item?.employers?.[0]?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'account_manager', group: 'job', label: 'Account manager',
                    tooltip : { title: 'Account manager', description: 'The internal account manager responsible for overseeing the recruitment process and ensuring its smooth progression.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'accountManager_asc', desc: 'accountManager_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'account_manager', title: 'Account manager', data: ( item, ctx ) => { return item?.accountManagers?.[0]?.name } },
                            { id: 'account_manager_organization', title: 'Account manager organization', data: ( item, ctx ) => { return item?.accountManagers?.[0]?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'salary', group: 'job', label: 'Salary/Rate',
                    tooltip : { title: 'Salary/Rate', description: 'Displays the job’s salary, including the amount, currency, and rate (hourly, monthly, or yearly).' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'salary_asc', desc: 'salary_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'salary', title: 'Salary', data: ( item, ctx ) =>
                                {
                                    if( !item.employment?.salary?.flag?.includes('tbc') && item.employment?.salary )
                                    {
                                        if( !Helpers.equals( item.employment?.salary?.range?.min, item.employment?.salary?.range?.max ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.employment.salary.range.min, item.employment.salary.currency, ctx?.$defaultCurrency || item.employment?.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.employment.salary.range.max, item.employment.salary.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.employment.salary.range.min, item.employment.salary.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                    else
                                    {
                                        return `To be confirmed`;
                                    }
                                }
                            },
                            {
                                id: 'salary_currency', title: 'Salary currency', data: ( item, ctx ) =>
                                {
                                    if( !item.employment?.salary?.flag?.includes('tbc') && item.employment?.salary )
                                    {
                                        return ( ctx?.$defaultCurrency || item?.employment?.salary?.currency );
                                    }
                                }
                            },
                            {
                                id: 'salary_rate', title: 'Salary rate', data: ( item, ctx ) =>
                                {
                                    if( !item.employment?.salary?.flag?.includes('tbc') && item?.employment?.salary?.rate )
                                    {
                                        return item?.employment?.salary?.rate;
                                    }
                                    else
                                    {
                                        return 'Negotiable';
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'recruitment_fee', group: 'job', label: 'Agency fee',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'recruiter' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee_amount', title: 'Agency fee amount', data: ( item, ctx ) =>
                                {
                                    if( item?.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.recruitment.range.max.amount, item.summary.fees.recruitment.range.max.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.markup, item.summary.fees.recruitment.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup } - ${ item.summary.fees.recruitment.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                /*
                {
                    id      : 'supplier_fee', group: 'job', label: 'RAMP Handling fee',
                    tooltip : { title: 'RAMP Handling fee', description: 'Fees payable by the agency to RAMP.Global on successful placements. This is deducted from the recruitment fee and the agency is expected to invoice RAMP.Global with the handling fee having already been deducted.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'supplierFee_asc', desc: 'supplierFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'supplier_fee_amount', title: 'Handling fee amount', data: ( item, ctx ) =>
                                {
                                    if( item?.summary?.fees?.agency?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.agency.range.min.amount, item.summary.fees.agency.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.agency.range.min.amount, item.summary.fees.agency.range.min.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.agency.range.max.amount, item.summary.fees.agency.range.max.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.agency.range.min.amount, item.summary.fees.agency.range.min.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'supplier_fee_currency', title: 'Handling fee currency', data: ( item, ctx ) =>
                                {
                                    if( item?.summary.fees?.agency?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'supplier_fee_percentage', title: 'Handling fee in %', data: ( item, ctx ) =>
                                {
                                    if( item?.summary.fees?.agency?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.agency.range.min.markup, item.summary.fees.agency.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.agency.range.min.markup } - ${ item.summary.fees.agency.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.agency.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_fee', group: 'job', label: 'Employer handling fee',
                    tooltip : { title: 'Employer handling fee', description: 'Fees payable by the employer to RAMP.Global on successful placements. This is added on to the placement fee and the employer is expected to pay this in addition to the placement fee to RAMP.Global.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'employerFee_asc', desc: 'employerFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'employer_fee_amount', title: 'Employer handling fee amount', data: ( item, ctx ) =>
                                {
                                    if( item?.summary.fees?.employer?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.employer.range.min.amount, item.summary.fees.employer.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.employer.range.min.amount, item.summary.fees.employer.range.min.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.employer.range.max.amount, item.summary.fees.employer.range.max.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.employer.range.min.amount, item.summary.fees.employer.range.min.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'employer_fee_currency', title: 'Employer handling fee currency', data: ( item, ctx ) =>
                                {
                                    if( item?.summary.fees?.employer?.range?.min?.amount )
                                    {
                                         return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'employer_fee_percentage', title: 'Employer handling fee in %', data: ( item, ctx ) =>
                                {
                                    if( item?.summary.fees?.employer?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.employer.range.min.markup, item.summary.fees.employer.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.employer.range.min.markup } - ${ item.summary.fees.employer.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.employer.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                */
                {
                    id      : 'positions_total', group: 'job', label: 'Jobs total',
                    tooltip : { title: 'Jobs total', description: 'The total number of positions that are available for this job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'positionsTotal_asc', desc: 'positionsTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'positions_total', title: 'Positions total', data: ( item, ctx ) => { return item?.summary?.positions?.total } }
                        ]
                    }
                },
                {
                    id      : 'engagements_active', group: 'job', label: 'Active engagements',
                    tooltip : { title: 'Active engagements', description: 'Shows the total number of recruitment agencies engaged to fill this job. Hover for more details like agency fee.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    sort    : { asc: 'engagementsActive_asc', desc: 'engagementsActive_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'engagements_active', title: 'Active engagements', data: ( item, ctx ) => { return item?.summary?.engagements?.active } }
                        ]
                    }
                },
                {
                    id      : 'applications_active', group: 'job', label: 'Active applications',
                    tooltip : { title: 'Active applications', description: 'Displays the number of applications submitted for this job. Hover to view details about the applicants and the application status.' },
                    align   : 'center',
                    scope   : [ 'admin', 'recruiter', 'employer' ],
                    sort    : { asc: 'applicationsActive_asc', desc: 'applicationsActive_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'applications_active', title: 'Active applications', data: ( item, ctx ) => { return item?.summary?.applications?.active } }
                        ]
                    }
                },
                {
                    id      : 'latest_interaction', group: 'job', label: 'Latest interaction',
                    tooltip : { title: 'Latest interaction', description: 'The latest interaction made for the job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'latestInteract_asc', desc: 'latestInteract_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'latest_interaction', title: 'Latest interaction', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.refreshed )
                                    {
                                        return new Date( item.events.refreshed ).toUTCDate()
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'start_date', group: 'job', label: 'Start date',
                    tooltip : { title: 'Start date', description: 'The start date of the job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'startDate_asc', desc: 'startDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'start_date', title: 'Start date', data: ( item, ctx ) =>
                                {
                                    if( item?.employment?.interval?.start )
                                    {
                                        return new Date( item.employment.interval.start ).toUTCDate()
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'end_date', group: 'job', label: 'End date',
                    tooltip : { title: 'End date', description: 'The end date of the job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'endDate_asc', desc: 'endDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'end_date', title: 'End date', data: ( item, ctx ) =>
                                {
                                    if( item?.employment?.interval?.end )
                                    {
                                        return new Date( item.employment.interval.end ).toUTCDate()
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'created', group: 'job', label: 'Created',
                    tooltip : { title: 'Created', description: 'The date when the job was created.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'createdDate', title: 'Created', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate()
                                    }
                                }
                            }
                        ]
                    }
                },
            ],

            presets:
            [
                { id: 'admin', name: 'default', columns: [ "open_jobs", "title", "reference", "stage", "location", "engagements_active", "applications_active", "employer", "account_manager", "latest_interaction", "created" ] },
                { id: 'employer', name: 'default', columns: [ "open_jobs", "title", "reference", "stage", "salary", "location", "engagements_active", "applications_active", "account_manager", "latest_interaction", "created" ] },
                { id: 'recruiter', name: 'default', columns: [ "open_jobs", "title", "reference", "salary", "recruitment_fee", "location", "applications_active", "skills", "employer", "account_manager", "latest_interaction", "created" ] },
            ]
        }
    },

    invitationList: //DONE
    {
        grid    : { columns : 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'invitation', label: 'Invitation' }
            ],

            columns:
            [
                {
                    id      : 'open_jobs', group: 'job', label: 'Open jobs',
                    tooltip : { title: 'Open jobs', description: 'The total number of available positions that are open for this job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'openJobsTotal_asc', desc: 'openJobsTotal_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'open_jobs', title: 'Open jobs', data: ( item, ctx ) => { return item?.job?.summary?.positions?.open } }
                        ]
                    }
                },
                {
                    id      : 'title', group: 'invitation', label: 'Job requisition',
                    tooltip : { title: 'Job requisition', description: 'Displays the job’s status, title and contract type. The status indicates whether the position is active, closed, or on hold.' },
                    width   : '384px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'reference', title: 'Reference', data: ( item, ctx ) => { return item?.job?.reference } },
                            { id: 'status', title: 'Requisition status', data: ( item, ctx ) => { return item?.job?.status } },
                            { id: 'title', title: 'Title', data: ( item, ctx ) => { return item?.job?.title } },
                            { id: 'contractType', title: 'Contract type', data: ( item, ctx ) => { return item?.job?.employment?.type } },
                        ]
                    }
                },
                {
                    id      : 'reference', group: 'job', label: 'Reference',
                    tooltip : { title: 'Reference', description: 'Shows the employer’s internal reference or unique ID for the job posting. This number is used for tracking and management purposes.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'reference_asc', desc: 'reference_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'reference', title: 'Reference', data: ( item, ctx ) => { return item?.job?.reference } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'invitation', label: 'Status',
                    tooltip : { title: 'Job', description: 'The current status of the invitation, which can be accepted, pending, or rejected.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'status_asc', desc: 'status_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Invitation Status', data: ( item, ctx ) => { return item?.status } },
                        ]
                    }
                },
                {
                    id      : 'category', group: 'invitation', label: 'Category',
                    tooltip : { title: 'Category', description: 'Specifies the field or industry the job belongs to, such as IT, Business, or Healthcare, helping to categorize and filter the job.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'category_asc', desc: 'category_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'category', title: 'Category', data: ( item, ctx ) => { return item?.job?.category?.label } }
                        ]
                    }
                },
                {
                    id      : 'salary', group: 'invitation', label: 'Salary/Rate',
                    tooltip : { title: 'Salary/Rate', description: 'Shows the job’s salary details, including the amount, currency, and whether it’s based on an hourly, monthly, or yearly rate.' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'salary_asc', desc: 'salary_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'salary', title: 'Salary', data: ( item, ctx ) =>
                                {
                                    if( !item.job.employment?.salary?.flag?.includes('tbc') && item.job.employment?.salary )
                                    {
                                        if( !Helpers.equals( item.job.employment?.salary?.range?.min, item.job.employment?.salary?.range?.max ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.job.employment.salary.range.min, item.job.employment.salary.currency, ctx?.$defaultCurrency || item.job.employment?.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.job.employment.salary.range.max, item.job.employment.salary.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.job.employment.salary.range.min, item.job.employment.salary.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                    else
                                    {
                                        return `To be confirmed`;
                                    }
                                }
                            },
                            {
                                id: 'salary_currency', title: 'Salary currency', data: ( item, ctx ) =>
                                {
                                    if( !item.job.employment?.salary?.flag?.includes('tbc') && item.job.employment?.salary )
                                    {
                                        return ( ctx?.$defaultCurrency || item.job?.employment?.salary?.currency );
                                    }
                                }
                            },
                            {
                                id: 'salary_rate', title: 'Salary rate', data: ( item, ctx ) =>
                                {
                                    if( !item.job.employment?.salary?.flag?.includes('tbc') && item.job?.employment?.salary?.rate )
                                    {
                                        return item.job?.employment?.salary?.rate;
                                    }
                                    else
                                    {
                                        return 'Negotiable';
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'recruitment_fee', group: 'invitation', label: 'Agency fee',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'employer', 'recruiter' ],
                    //sort    : { asc: 'agencyFee_asc', desc: 'agencyFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee_amount', title: 'Agency fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.recruitment.range.max.amount, item.summary.fees.recruitment.range.max.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.job.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.markup, item.summary.fees.recruitment.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup } - ${ item.summary.fees.recruitment.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'agency_recruitment_fee', group: 'invitation', label: 'Agency fee ( agency )',
                    tooltip : { title: 'Agency fee ( agency )', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort    : { asc: 'agencyFee_asc', desc: 'agencyFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee_amount', title: 'Agency fee amount ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.agencyRecruitment.range.min.amount, item.summary.fees.agencyRecruitment.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.agencyRecruitment.range.min.amount, item.summary.fees.agencyRecruitment.range.min.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.agencyRecruitment.range.max.amount, item.summary.fees.agencyRecruitment.range.max.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.agencyRecruitment.range.min.amount, item.summary.fees.agencyRecruitment.range.min.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.job.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in % ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.agencyRecruitment.range.min.markup, item.summary.fees.agencyRecruitment.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.agencyRecruitment.range.min.markup } - ${ item.summary.fees.agencyRecruitment.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.agencyRecruitment.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_recruitment_fee', group: 'invitation', label: 'Agency fee ( employer )',
                    tooltip : { title: 'Agency fee ( employer )', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort    : { asc: 'agencyFee_asc', desc: 'agencyFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee_amount', title: 'Agency fee amount ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.recruitment.range.max.amount, item.summary.fees.recruitment.range.max.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || item.job.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.job.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in % ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.markup, item.summary.fees.recruitment.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup } - ${ item.summary.fees.recruitment.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'engagement_price', group: 'invitation', label: 'Engagement price',
                    tooltip : { title: 'Engagement price', description: 'If the job requires a payment to engage, this displays the price of the job engagement.' },
                    align   : 'right',
                    scope   : [ 'admin', 'recruiter' ],
                    //sort    : { asc: 'engagementPrice_asc', desc: 'engagementPrice_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'engagement_price', title: 'Engagement price', data: ( item, ctx ) =>
                                {
                                    if( item?.engagement?.price )
                                    {
                                        return Format.currency( Commercials.convertCurrency( item.engagement.price, item.engagement.currency, ctx?.$defaultCurrency || item.engagement.currency ), ctx?.$defaultCurrency || item.engagement.currency );
                                    }
                                    else
                                    {
                                        return 'Free to engage';
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'inviter', group: 'invitation', label: 'Inviter',
                    tooltip : { title: 'Inviter', description: 'The person who sent the invitation to the agency, including their name and company title.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'inviter_asc', desc: 'inviter_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'inviter', title: 'Inviter', data: ( item, ctx ) => { return item?.senderAccount?.name } },
                            { id: 'inviter_organization', title: 'Inviter organization', data: ( item, ctx ) => { return item?.senderAccount?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'recruiter', group: 'invitation', label: 'Recruiter',
                    tooltip : { title: 'Recruiter', description: 'The recruiter from the agency responsible for covering this job, showing their name and agency title.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruiter_asc', desc: 'recruiter_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'recruiter', title: 'Recruiter', data: ( item, ctx ) => { return item?.recruiterAccount?.name } },
                            { id: 'recruiter_organization', title: 'Recruiter organization', data: ( item, ctx ) => { return item?.recruiterAccount?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'agency', group: 'invitation', label: 'Agency',
                    tooltip : { title: 'Agency', description: 'Displays the agency’s name and recruiter from the agency responsible for covering this job.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruiter_asc', desc: 'recruiter_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'agency', title: 'Agency', data: ( item, ctx ) => { return item?.recruiterAccount?.organization?.name } },
                            { id: 'agency_organization', title: 'Agency recruiter', data: ( item, ctx ) => { return item?.recruiterAccount?.name } },
                        ]
                    }
                },
                {
                    id      : 'programme', group: 'invitation', label: 'Program',
                    tooltip : { title: 'Program', description: 'Refers to the agreed setup for the job, including key commercial terms like recruitment fees, rebate policies, and other related details.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'programme_asc', desc: 'programme_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'programme', title: 'Program', data: ( item, ctx ) => { return item?.programme?.title } },
                            { id: 'programme_location', title: 'Program location', data: ( item, ctx ) => { return item.job?.programmeLocation?.title } },
                        ]
                    }
                },
                {
                    id      : 'release', group: 'invitation', label: 'Release type',
                    tooltip : { title: 'Release type', description: 'Indicates the agency matching release type, which can be automatic or manual.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'release_asc', desc: 'release_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'release', title: 'Release type', data: ( item, ctx ) => { return item.release.capitalize() } },
                        ]
                    }
                },
                {
                    id      : 'created', group: 'invitation', label: 'Created',
                    tooltip : { title: 'Created', description: 'The date when the invitation was created.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'createdDate', title: 'Created', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate()
                                    }
                                }
                            }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: "admin", name: "default", columns: [ "open_jobs", "title", "reference", "status", "category", "engagement_price", "agency_recruitment_fee", "employer_recruitment_fee", "salary", "inviter", "recruiter", "programme", "created" ] },
                { id: "employer", name: "default", columns: [ "open_jobs", "title", "reference", "status", "category", "recruitment_fee", "salary", "inviter", "recruiter", "programme", "created" ] },
                { id: "recruiter", name: "default", columns: [ "open_jobs", "title", "reference", "status", "category", "engagement_price", "recruitment_fee", "salary", "inviter", "recruiter", "programme", "created" ] },

                { id: "ramp.recruitment.job.invitation", name: "default", columns: [ "agency", "status", "agency_recruitment_fee", "employer_recruitment_fee", "inviter", "release", "created" ] },
                { id: "ramp.recruitment.job.invitation", name: "default", columns: [ "agency", "status", "recruitment_fee", "inviter", "release", "created" ] },
                { id: "ramp.recruitment.job.invitation", name: "default", columns: [ "agency", "status", "recruitment_fee", "inviter", "release", "created" ] },
            ]
        }
    },

    engagementList:
    {
        grid    : { columns : 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'engagement', label: 'Engagement' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'engagement', label: 'Agency',
                    tooltip : { title: 'Agency', description: 'Displays the agency’s name and recruiter from the agency responsible for covering this job.' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'agency', title: 'Agency', data: ( item, ctx ) => { return item?.recruiter?.organization?.name } },
                            { id: 'agency_user', title: 'Agency user', data: ( item, ctx ) => { return item?.recruiter?.name } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'engagement', label: 'Status',
                    tooltip : { title: 'Status', description: 'The current status of the engagement.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'status_asc', desc: 'status_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Engagement status', data: ( item, ctx ) => { return item?.status?.capitalize() } }
                        ]
                    }
                },
                {
                    id      : 'agency_recruitment_fee', group: 'engagement', label: 'Agency fee ( agency )',
                    tooltip : { title: 'Agency fee ( agency )', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    sort    : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'agencyRecruitment_fee_amount', title: 'Agency fee amount ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.agencyRecruitment.range.min.amount, item.summary.fees.agencyRecruitment.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.agencyRecruitment.range.min.amount, item.summary.fees.agencyRecruitment.range.min.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.agencyRecruitment.range.max.amount, item.summary.fees.agencyRecruitment.range.max.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.agencyRecruitment.range.min.amount, item.summary.fees.agencyRecruitment.range.min.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'agencyRecruitment_fee_currency', title: 'Agency fee currency ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'agencyRecruitment_fee_percentage', title: 'Agency fee in % ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.agencyRecruitment.range.min.markup, item.summary.fees.agencyRecruitment.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.agencyRecruitment.range.min.markup } - ${ item.summary.fees.agencyRecruitment.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.agencyRecruitment.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_recruitment_fee', group: 'engagement', label: 'Agency fee ( employer )',
                    tooltip : { title: 'Agency fee ( employer )', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    sort    : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee_amount', title: 'Agency fee amount ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.recruitment.range.max.amount, item.summary.fees.recruitment.range.max.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in % ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.markup, item.summary.fees.recruitment.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup } - ${ item.summary.fees.recruitment.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'recruitment_fee', group: 'engagement', label: 'Agency fee',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'employer', 'recruiter' ],
                    sort    : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee_amount', title: 'Agency fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.max.amount ) )
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) } - ${ Commercials.convertCurrency( item.summary.fees.recruitment.range.max.amount, item.summary.fees.recruitment.range.max.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) }`;
                                        }
                                        else
                                        {
                                            return `${ Commercials.convertCurrency( item.summary.fees.recruitment.range.min.amount, item.summary.fees.recruitment.range.min.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed(2) }`;
                                        }
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.markup )
                                    {
                                        if( !Helpers.equals( item.summary.fees.recruitment.range.min.markup, item.summary.fees.recruitment.range.max.markup ) )
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup } - ${ item.summary.fees.recruitment.range.max.markup }`;
                                        }
                                        else
                                        {
                                            return `${ item.summary.fees.recruitment.range.min.markup }`;
                                        }
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'candidates', group: 'engagement', label: 'Applications',
                    tooltip : { title: 'Applications', description: 'Total number of submitted applications by the agency to the job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'candidates_asc', desc: 'candidates_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'candidates', title: 'Applications', data: ( item, ctx ) => { return item?.applications?.length } }
                        ]
                    }
                },
                {
                    id      : 'slots', group: 'engagement', label: 'CV slots',
                    tooltip : { title: 'CV slots', description: 'Total number of CV slots available for the agency to submit applications to the job.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'cv_slots_asc', desc: 'ccv_slots_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'slots', title: 'CV slots', data: ( item, ctx ) => { return item?.cvs } }
                        ]
                    }
                },
                {
                    id      : 'engagementDate', group: 'engagement', label: 'Engagement date',
                    tooltip : { title: 'Engagement date', description: 'The date when the engagement was created.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'engagementDate_asc', desc: 'engagementDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'date', title: 'Engagement date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'disengagementDate', group: 'engagement', label: 'Disengagement date',
                    tooltip : { title: 'Disengagement date', description: 'The date when the engagement was closed.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'disengagementDate_asc', desc: 'disengagementDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'date', title: 'Disengagement date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.closed ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                }
            ],

            presets:
            [
                //{ id: 'default', name: 'default', columns: [ 'title', /*'recruitment_fee',*/ 'candidates', 'date' ] },
                { id: 'admin', name: 'default', columns: [ 'title', 'status', 'agency_recruitment_fee', 'employer_recruitment_fee', 'candidates', 'slots', 'engagementDate', 'disengagementDate' ] },
                { id: 'employer', name: 'default', columns: [ 'title', 'status', 'recruitment_fee', 'candidates', 'slots', 'engagementDate', 'disengagementDate' ] },
                { id: 'recruiter', name: 'default', columns: [ 'title', 'status', 'recruitment_fee', 'candidates', 'slots', 'engagementDate', 'disengagementDate' ] }
            ]
        }
    },

    recruitmentList:
    {
        grid    : { columns : 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'recruitment', label: 'Recruitment' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'recruitment', label: 'Agency user',
                    tooltip : { title: 'Agency user', description: 'The agency user of the recruitment' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'recruitment', title: 'Agency user', data: ( item, ctx ) => { return item?.name } },
                            { id: 'recruitment_position', title: 'Position', data: ( item, ctx ) => { return item?.position?.title } }
                        ]
                    }
                },
                {
                    id      : 'fill_rate', group: 'recruitment', label: 'Avg. fill rate',
                    tooltip : { title: 'Avg. fill rate', description: 'The average fill rate of the recruitment' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'fill_rate', title: 'Avg. fill rate', data: ( item, ctx ) => { return item?.summary?.avgFillRate } }
                        ]
                    }
                },
                {
                    id      : 'jobs', group: 'recruitment', label: 'Jobs',
                    tooltip : { title: 'Jobs', description: 'The number of jobs for the recruitment' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'jobs', title: 'Jobs', data: ( item, ctx ) => { return item?.summary?.jobs } }
                        ]
                    }
                },
                {
                    id      : 'applications', group: 'recruitment', label: 'Applications',
                    tooltip : { title: 'Applications', description: 'The number of applications for the recruitment' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'applications', title: 'Applications', data: ( item, ctx ) => { return item?.summary?.applications } }
                        ]
                    }
                },
                {
                    id      : 'placements', group: 'recruitment', label: 'Placements',
                    tooltip : { title: 'Placements', description: 'The number of placements for the recruitment' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'placements', title: 'Placements', data: ( item, ctx ) => { return item?.summary?.placements } }
                        ]
                    }
                },
                {
                    id      : 'contracts', group: 'recruitment', label: 'Contractors',
                    tooltip : { title: 'Contractors', description: 'The number of contractors for the recruitment' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'contracts', title: 'Contractors', data: ( item, ctx ) => { return item?.summary?.contractors } }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'fill_rate', 'jobs', 'applications', 'placements', 'contracts' ] }
            ]
        }
    },

    applicationList: //DONE
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'application', label: 'Application' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'application', label: 'Application',
                    tooltip : { title: 'Application', description: 'Contains the candidate’s name, contract type, and the title of the job for which they applied.' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'candidate_name', title: 'Candidate name', data: ( item, ctx ) => { return item?.candidate?.name } },
                            { id: 'job_title', title: 'Job title', data: ( item, ctx ) => { return item?.job?.title } },
                            { id: 'employment_type', title: 'Employment type', data: ( item, ctx ) => { return item?.job?.employment?.type } },
                        ]
                    }
                },
                {
                    id      : 'reference', group: 'application', label: 'Reference',
                    tooltip : { title: 'Reference', description: 'Displays the employer’s internal reference number or unique ID for the job posting, used for tracking and management.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'reference_asc', desc: 'reference_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'reference', title: 'Reference', data: ( item, ctx ) => { return item?.jobReference } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'application', label: 'Status',
                    tooltip : { title: 'Status', description: 'The current status of the application. Possible statuses include: Submitted, Reviewing, Interviewing, Under Offer, Hired, Withdrawn, Rejected, or Transferred.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'status_asc', desc: 'status_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Status', data: ( item, ctx ) => { return item?.status } },
                        ]
                    }
                },
                {
                    id      : 'availability', group: 'application', label: 'Availability',
                    tooltip : { title: 'Availability', description: 'Shows the candidate’s availability, indicating when they can start the new job.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'availability_asc', desc: 'availability_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'availability', title: 'Availability', data: ( item, ctx ) => { return 'Unknown' } }, // TODO AVAILABILITY
                        ]
                    }
                },
                {
                    id      : 'category', group: 'application', label: 'Category',
                    tooltip : { title: 'Category', description: 'Lists the field or industry the job belongs to, such as IT, Business, Healthcare, etc. It helps categorize the job for better filtering and searching.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'category_asc', desc: 'category_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'category', title: 'Category', data: ( item, ctx ) => { return item?.job?.category?.label } },
                        ]
                    }
                },
                {
                    id      : 'skills', group: 'application', label: 'Skills',
                    tooltip : { title: 'Skills', description: 'Displays the candidate’s skills and qualifications.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'skills_asc', desc: 'skills_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'skills', title: 'Skills', data: ( item, ctx ) => { return item?.skills?.map( s => s.label )?.join(', ')  } },
                        ]
                    }
                },
                {
                    id      : 'location', group: 'application', label: 'Location',
                    tooltip : { title: 'Location', description: 'Displays the candidate’s country where they are currently residing.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'locations_asc', desc: 'locations_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'location', title: 'Location', data: ( item, ctx ) => { return item?.candidate?.residence?.country?.name } },
                        ]
                    }
                },
                {
                    id      : 'remote_work', group: 'application', label: 'Remote work',
                    tooltip : { title: 'Remote work', description: 'Shows whether the candidate is open to remote work opportunities.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'remoteWork_asc', desc: 'remoteWork_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'remote_work', title: 'Remote work', data: ( item, ctx ) => { return item?.remote ? 'Yes' : 'No' } },
                        ]
                    }
                },
                {
                    id      : 'programme', group: 'application', label: 'Program',
                    tooltip : { title: 'Program', description: 'A program refers to an agreed setup for the job, including key commercial details such as the recruitment fee, rebate policy, and other related terms.' },
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'programme_asc', desc: 'programme_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'programme', title: 'Program', data: ( item, ctx ) => { return item?.programme?.title } },
                            { id: 'programme_location', title: 'Program location', data: ( item, ctx ) => { return item?.programmeLocation?.title } },
                        ]
                    }
                },
                {
                    id      : 'recruiter', group: 'application', label: 'Agency',
                    tooltip : { title: 'Agency', description: 'Displays the agency’s name and recruiter from the agency responsible for covering this job.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruiter_asc', desc: 'recruiter_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'recruiter', title: 'Agency name', data: ( item, ctx ) => { return item.recruiter?.organization?.name } },
                            { id: 'recruiter_user', title: 'Agency user', data: ( item, ctx ) => { return item.recruiter?.name } },
                        ]
                    }
                },
                {
                    id      : 'employer', group: 'application', label: 'Job manager',
                    tooltip : { title: 'Job manager', description: 'The name of the user who submitted the job posting, along with their company title.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'employer_asc', desc: 'employer_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'employer', title: 'Job manager name', data: ( item, ctx ) => { return item?.employers?.[0]?.name } },
                            { id: 'employer_organization', title: 'Job manager organization', data: ( item, ctx ) => { return item?.employers?.[0]?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'account_manager', group: 'application', label: 'Account manager',
                    tooltip : { title: 'Account manager', description: 'The internal account manager responsible for overseeing the recruitment process and ensuring its smooth progression.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'accountManager_asc', desc: 'accountManager_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'account_manager', title: 'Account manager', data: ( item, ctx ) => { return item?.accountManagers?.[0]?.name } },
                            { id: 'account_manager_organization', title: 'Account manager organization', data: ( item, ctx ) => { return item?.accountManagers?.[0]?.name } },
                        ]
                    }
                },
                {
                    id      : 'salary', group: 'application', label: 'Salary',
                    tooltip : { title: 'Salary', description: 'Displays the candidate’s expected salary, including the amount and currency.' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'salary_asc', desc: 'salary_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'salary', title: 'Salary', data: ( item, ctx ) =>
                                {
                                    if( item?.salary?.amount )
                                    {
                                        return Commercials.convertCurrency( item.salary.amount, item.salary.currency, ctx?.$defaultCurrency || item.salary.currency ).toFixed(2)
                                    }
                                }
                            },
                            { id: 'salary_currency', title: 'Salary currency', data: ( item, ctx ) =>
                                {
                                    if( item?.salary?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.salary.currency )
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'recruitment_fee', group: 'application', label: 'Agency fee',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee', title: 'Agency fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.recruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.recruitment.amount.amount, item.summary.fees.recruitment.amount.currency, ctx?.$defaultCurrency || item.salary.currency ).toFixed(2);
                                    }
                                },
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.range?.min?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.recruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.recruitment.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'agency_recruitment_fee', group: 'application', label: 'Agency fee ( agency )',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'agencyRecruitment_fee', title: 'Agency fee amount ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.agencyRecruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.agencyRecruitment.amount.amount, item.summary.fees.agencyRecruitment.amount.currency, ctx?.$defaultCurrency || item.salary.currency ).toFixed(2);
                                    }
                                },
                            },
                            {
                                id: 'agencyRecruitment_fee_currency', title: 'Agency fee currency ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.recruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'agencyRecruitment_fee_percentage', title: 'Agency fee in % ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.agencyRecruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.agencyRecruitment.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_recruitment_fee', group: 'application', label: 'Agency fee ( employer )',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee', title: 'Agency fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.recruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.recruitment.amount.amount, item.summary.fees.recruitment.amount.currency, ctx?.$defaultCurrency || item.salary.currency ).toFixed(2);
                                    }
                                },
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.recruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary.fees?.recruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.recruitment.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                /*
                {
                    id      : 'supplier_fee', group: 'application', label: 'Agency handling fee',
                    tooltip : { title: 'Agency handling fee', description: 'Fees payable by the agency to RAMP.Global on successful placements. This is deducted from the recruitment fee and the agency is expected to invoice RAMP.Global with the handling fee having already been deducted.' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'supplierFee_asc', desc: 'supplierFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'supplier_fee', title: 'Agency handling fee amount', data: ( item, ctx ) =>
                                {
                                    if( item?.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.agency.amount.amount, item.summary.fees.agency.amount.currency, ctx?.$defaultCurrency || item.salary.currency ).toFixed(2);
                                    }
                                },
                            },
                            { id: 'supplier_fee_percentage', title: 'Agency handling fee in %', data: ( item, ctx ) =>
                                {
                                    if( item?.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return item.summary.fees.agency.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_fee', group: 'application', label: 'Employer handling fee',
                    tooltip : { title: 'Employer handling fee', description: 'Fees payable by the employer to RAMP.Global on successful placements. This is added on to the placement fee and the employer is expected to pay this in addition to the placement fee to RAMP.Global. ' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'employerFee_asc', desc: 'employerFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'employer_fee', title: 'Employer handling fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.employer?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.employer.amount.amount, item.summary.fees.employer.amount.currency, ctx?.$defaultCurrency || item.salary.currency ).toFixed(2);
                                    }
                                },
                            },
                            { id: 'employer_fee_percentage', title: 'Agency handling fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.employer?.amount?.amount )
                                    {
                                        return item.summary.fees.employer.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                */
                {
                    id      : 'updated_date', group: 'application', label: 'Updated',
                    tooltip : { title: 'Updated', description: 'The date when the application was last updated.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'updatedDate_asc', desc: 'updatedDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'updated_date', title: 'Updated', data: ( item, ctx ) => { return new Date( item.events.updated ).toUTCDate() } },
                        ]
                    }
                },
                {
                    id      : 'created_date', group: 'application', label: 'Created',
                    tooltip : { title: 'Created', description: 'The date when the application was created.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'created_date', title: 'Created', data: ( item, ctx ) => { return new Date( item.events.created ).toUTCDate() } },
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'admin', name: 'default', columns: [ "title", "status", "recruiter", "agency_recruitment_fee", "employer_recruitment_fee", "employer", "updated_date", "created_date" ] },
                { id: 'employer', name: 'default', columns: [ "title", "status", "salary", "recruitment_fee", "availability", "location", "recruiter", "account_manager", "updated_date", "created_date" ] },
                { id: 'recruiter', name: 'default', columns: [ "title", "status", "salary", "recruitment_fee", "employer", "account_manager", "updated_date", "created_date" ] },
            ]
        }
    },

    placementList: //DONE
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'placement', label: 'Placement' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'placement', label: 'Placement',
                    tooltip : { title: 'Placement', description: 'The title of the placement' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'name', title: 'Employee name', data: ( item, ctx ) => { return item?.employee?.name } },
                            { id: 'type', title: 'Employment type', data: ( item, ctx ) => { return item?.employment?.type } },
                        ]
                    }
                },
                {
                    id      : 'reference', group: 'placement', label: 'Reference',
                    tooltip : { title: 'Reference', description: 'Shows the employer’s internal reference or unique ID for the job posting. This number is used for tracking and management purposes.' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'reference_asc', desc: 'reference_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'reference', title: 'Reference', data: ( item, ctx ) => { return item?.jobReference } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'placement', label: 'Status',
                    tooltip : { title: 'Status', description: 'The status of the placement' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'status_asc', desc: 'status_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Status', data: ( item, ctx ) => { return item?.status } }
                        ]
                    }
                },
                {
                    id      : 'job', group: 'placement', label: 'Job requisition',
                    tooltip : { title: 'Job', description: 'The Job requisition of the placement' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'job_asc', desc: 'job_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'job', title: 'Job requisition', data: ( item, ctx ) => { return item?.title } }
                        ]
                    }
                },
                {
                    id      : 'category', group: 'placement', label: 'Category',
                    tooltip : { title: 'Category', description: 'The category of the job' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'category_asc', desc: 'category_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'category', title: 'Category', data: ( item, ctx ) => { return item?.category?.label } }
                        ]
                    }
                },
                {
                    id      : 'skills', group: 'placement', label: 'Skills',
                    tooltip : { title: 'Skills', description: 'The skills of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'skills_asc', desc: 'skills_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'skills', title: 'Skills', data: ( item, ctx ) => { return item?.skills?.map( s => s.label )?.join(', ') } }
                        ]
                    }
                },
                {
                    id      : 'location', group: 'placement', label: 'Location',
                    tooltip : { title: 'Location', description: 'The location of the job' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'location_asc', desc: 'location_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'location', title: 'Location', data: ( item, ctx ) => { return item?.location?.name } }
                        ]
                    }
                },
                {
                    id      : 'remote_work', group: 'placement', label: 'Remote work',
                    tooltip : { title: 'Remote work', description: 'The remote work availability of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'remoteWork_asc', desc: 'remoteWork_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'remote_work', title: 'Remote work', data: ( item, ctx ) => { return item?.remote ? 'Yes' : 'No' } }
                        ]
                    }
                },
                {
                    id      : 'programme', group: 'placement', label: 'Program',
                    tooltip : { title: 'Program', description: 'The program the job is associated with' },
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'programme_asc', desc: 'programme_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'programme', title: 'Program', data: ( item, ctx ) => { return item?.programme?.title } },
                            { id: 'programme_location', title: 'Program location', data: ( item, ctx ) => { return item?.programmeLocation?.title } }
                        ]
                    }
                },
                {
                    id      : 'confidential_role', group: 'job', label: 'Confidential role',
                    tooltip : { title: 'Confidential role', description: 'The confidentiality of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'confidentialRole_asc', desc: 'confidentialRole_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'confidential_role', title: 'Confidential role', data: ( item, ctx ) => { return item?.confidential ? 'Yes' : 'No' } }
                        ]
                    }
                },
                {
                    id      : 'recruiter', group: 'placement', label: 'Agency',
                    tooltip : { title: 'Agency', description: 'The agency of the placement' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruiter_asc', desc: 'recruiter_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'recruiter', title: 'Agency', data: ( item, ctx ) => { return item?.recruiter?.organization?.name } },
                            { id: 'recruiter_user', title: 'Agency user', data: ( item, ctx ) => { return item?.recruiter?.name } },
                        ]
                    }
                },
                {
                    id      : 'employer', group: 'placement', label: 'Job manager',
                    tooltip : { title: 'Job manager', description: 'The employer of the placement' },
                    scope   : [ 'admin', 'recruiter', 'recruiter' ],
                    //sort   : { asc: 'employer_asc', desc: 'employer_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'employer', title: 'Job manager', data: ( item, ctx ) => { return item?.employers?.[0]?.name } },
                            { id: 'employer_organization', title: 'Job manager organization', data: ( item, ctx ) => { return item?.employers?.[0]?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'account_manager', group: 'placement', label: 'Account manager',
                    tooltip : { title: 'Account manager', description: 'The account manager of the placement' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'accountManager_asc', desc: 'accountManager_desc' },
                    export :
                    {
                        columns:
                        [
                            { id: 'account_manager', title: 'Account manager', data: ( item, ctx ) => { return item?.contacts?.accountManagers?.[0]?.name } },
                            { id: 'account_manager_organization', title: 'Account manager organization', data: ( item, ctx ) => { return item?.contacts?.accountManagers?.[0]?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'salary', group: 'placement', label: 'Salary',
                    tooltip : { title: 'Salary', description: 'The salary of the placement' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'salary_asc', desc: 'salary_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'salary', title: 'Salary amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.salary?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.salary.amount, item.summary.fees.salary.currency, ctx?.$defaultCurrency || item.summary.fees.salary.currency ).toFixed( 2 );
                                    }
                                }
                            },
                            {
                                id: 'salary_currency', title: 'Salary currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.salary?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.summary.fees.salary.currency );
                                    }
                                }
                            },
                            { id: 'salary_rate', title: 'Salary rate', data: ( item, ctx ) => { return item.summary.fees.salary.rate } },
                        ]
                    }
                },
                {
                    id      : 'recruitment_fee', group: 'placement', label: 'Agency fee',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee', title: 'Agency fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.recruitment.amount.amount, item.summary.fees.recruitment.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed( 2 );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.recruitment.amount.markup;
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'agency_recruitment_fee', group: 'placement', label: 'Agency fee ( agency )',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee', title: 'Agency fee amount ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.recruitment.amount.amount, item.summary.fees.recruitment.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed( 2 );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in % ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.recruitment.amount.markup;
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'employer_recruitment_fee', group: 'placement', label: 'Agency fee ( employer )',
                    tooltip : { title: 'Agency fee', description: 'Fee that the agency receives when a job is filled. This is calculated using the agency fee percentage which is set by an employer for a particular job.' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee', title: 'Agency fee amount ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.recruitment.amount.amount, item.summary.fees.recruitment.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed( 2 );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in % ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.recruitment.amount.markup;
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'supplier_fee', group: 'placement', label: 'RAMP handling fee ( agency )',
                    tooltip : { title: 'RAMP handling fee ( agency )', description: 'Fees payable by the agency to RAMP.Global on successful placements. This is deducted from the recruitment fee and the agency is expected to invoice RAMP.Global with the handling fee having already been deducted' },
                    align   : 'right',
                    scope   : [ 'admin', 'recruiter' ],
                    //sort   : { asc: 'supplierFee_asc', desc: 'supplierFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'supplier_fee', title: 'RAMP handling fee ( agency ) amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.agency.amount.amount, item.summary.fees.agency.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'supplier_fee_currency', title: 'RAMP handling fee ( agency ) currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'supplier_fee_percentage', title: 'RAMP handling fee ( agency ) in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return item.summary.fees.agency.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_fee', group: 'placement', label: 'RAMP handling fee ( employer )',
                    tooltip : { title: 'RAMP handling fee ( employer )', description: 'Fees payable by the employer to RAMP.Global on successful placements. This is added on to the placement fee and the employer is expected to pay this in addition to the placement fee to RAMP.Global.' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'employerFee_asc', desc: 'employerFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'employer_fee', title: 'RAMP handling fee ( employer ) amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.employer?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.employer.amount.amount, item.summary.fees.employer.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'employer_fee_currency', title: 'RAMP handling fee ( employer ) currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.employer?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'employer_fee_percentage', title: 'RAMP handling fee ( employer ) in %', data: ( item, ctx ) =>
                                {
                                    if( item?.summary?.fees?.employer?.amount?.markup )
                                    {
                                        return item.summary.fees.employer.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'rebate_period', group: 'placement', label: 'Rebate period',
                    tooltip : { title: 'Rebate period', description: 'The time period in which if a candidate has dropped out, an agency may be required to pay back a percentage of the agency fee.' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'rebatePeriod_asc', desc: 'rebatePeriod_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'rebate_period', title: 'Rebate period', data: ( item, ctx ) =>
                                {
                                    if( item?.summary?.rebate?.daysTillZero > 0 )
                                    {
                                        return `${ item.summary.rebate.daysTillZero } days left`;
                                    }
                                    else
                                    {
                                        return 'Expired';
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_invoice', group: 'placement', label: 'Employer invoice',
                    tooltip : { title: 'Employer invoice', description: 'The employer invoice status of the placement' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'employerInvoice_asc', desc: 'employerInvoice_desc' },
                    export :
                    {
                        columns:
                        [
                            {
                                id: 'employer_invoice',title: 'Employer invoice', data: ( item, ctx ) => { return item?.summary?.invoices?.employer?.status; }
                            }
                        ]
                    }
                },
                /*
                {
                    id     : 'employer_billing_entity', group: 'placement', label: 'Employer billing entity',
                    align  : 'center', tooltip: '<b>Hover</b> on any column to get <b>detailed info</b>!',
                    scope  : [ 'admin', 'employer' ],
                    //sort   : { asc: 'employerBillingEntity_asc', desc: 'employerBillingEntity_desc' },
                    export :
                    {
                        columns: [] // TODO
                    }
                },
                */
                {
                    id      : 'agency_invoice', group: 'placement', label: 'Agency invoice',
                    tooltip : { title: 'Agency invoice', description: 'The agency invoice status of the placement' },
                    align   : 'center',
                    scope   : [ 'admin', 'recruiter' ],
                    //sort   : { asc: 'agencyInvoice_asc', desc: 'agencyInvoice_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'agency_invoice', title: 'Agency invoice', data: ( item, ctx ) => { return item?.summary?.invoices?.agency?.status } }
                        ]
                    }
                },
                {
                    id      : 'start_date', group: 'placement', label: 'Start date',
                    tooltip : { title: 'Start date', description: 'The start date of the placement' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'startDate_asc', desc: 'startDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'start_date', title: 'Start date', data: ( item, ctx ) =>
                                {
                                    if( item?.employment?.interval?.start )
                                    {
                                        return new Date( item.employment.interval.start ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'end_date', group: 'placement', label: 'End date',
                    tooltip : { title: 'End date', description: 'The end date of the placement' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'endDate_asc', desc: 'endDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'end_date', title: 'End date', data: ( item, ctx ) =>
                                {
                                    if( item?.employment?.interval?.end )
                                    {
                                        return new Date( item.employment.interval.end ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'updated_date', group: 'placement', label: 'Updated date',
                    tooltip : { title: 'Updated date', description: 'The date when the placement was last updated' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'updatedDate_asc', desc: 'updatedDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'updated_date', title: 'Updated date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.updated )
                                    {
                                        return new Date( item.events.updated ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'created_date', group: 'placement', label: 'Created date',
                    tooltip : { title: 'Created date', description: 'The date when the placement was created' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created_date', title: 'Created date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'admin', name: 'default', columns: [ "title", "status", "salary", "agency_recruitment_fee", "employer_recruitment_fee", "supplier_fee", "employer_fee", "employer_invoice", "agency_invoice", "recruiter", "created_date" ] },
                { id: 'employer', name: 'default', columns: [ "title", "status", "job", "salary", "recruitment_fee", "employer_fee", "recruiter", "employer_invoice", "rebate_period" ] },
                { id: 'recruiter', name: 'default', columns: [ "title", "status", "job", "salary", "recruitment_fee", "supplier_fee", "agency_invoice", "rebate_period" ] }
            ]
        }
    },

    contractList: //DONE
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'contract', label: 'Contract' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'contract', label: 'Contractor',
                    tooltip : { title: 'Contractor', description: 'The title of the contractor' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'title_asc', desc: 'title_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'title', title: 'Contractor', data: ( item, ctx ) => { return item?.contractor?.name } }
                        ]
                    }
                },
                {
                    id      : 'reference', group: 'contract', label: 'Reference',
                    tooltip : { title: 'Reference', description: 'The reference of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'reference_asc', desc: 'reference_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'reference', title: 'Reference', data: ( item, ctx ) => { return item?.jobReference } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'contract', label: 'Status',
                    tooltip : { title: 'Status', description: 'The status of the contract' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'status_asc', desc: 'status_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Status', data: ( item, ctx ) => { return item?.status?.capitalize() } }
                        ]
                    }
                },
                {
                    id      : 'job', group: 'contract', label: 'Job requisition',
                    tooltip : { title: 'Job', description: 'The job of the contract' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'job_asc', desc: 'job_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'job', title: 'Job requisition', data: ( item, ctx ) => { return item?.title } }
                        ]
                    }
                },
                {
                    id      : 'category', group: 'contract', label: 'Category',
                    tooltip : { title: 'Category', description: 'The category of the job' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'category_asc', desc: 'category_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'category', title: 'Category', data: ( item, ctx ) => { return item?.category?.label } }
                        ]
                    }
                },
                {
                    id      : 'skills', group: 'contract', label: 'Skills',
                    tooltip : { title: 'Skills', description: 'The skills of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'skills_asc', desc: 'skills_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'skills', title: 'Skills', data: ( item, ctx ) => { return item?.skills?.map( skill => skill.label ).join(', ') } }
                        ]
                    }
                },
                {
                    id      : 'location', group: 'contract', label: 'Location',
                    tooltip : { title: 'Location', description: 'The location of the job' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'location_asc', desc: 'location_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'location',  title: 'Location', data: ( item, ctx ) => { return item?.location?.name } }
                        ]
                    }
                },
                {
                    id      : 'remote_work', group: 'contract', label: 'Remote work',
                    tooltip : { title: 'Remote work', description: 'The remote work availability of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'remoteWork_asc', desc: 'remoteWork_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'remote_work', title: 'Remote work', data: ( item, ctx ) => { return item.remote ? 'Yes' : 'No' } }
                        ]
                    }
                },
                {
                    id      : 'confidential_role', group: 'job', label: 'Confidential role',
                    tooltip : { title: 'Confidential role', description: 'The confidentiality of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'confidentialRole_asc', desc: 'confidentialRole_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'confidential_role', title: 'Confidential role', data: ( item, ctx ) => { return item?.confidential ? 'Yes' : 'No' } }
                        ]
                    }
                },
                {
                    id      : 'programme', group: 'contract', label: 'Program',
                    tooltip : { title: 'Program', description: 'The program the job is associated with' },
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'programme_asc', desc: 'programme_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'programme', title: 'Program', data: ( item, ctx ) => { return item?.programme?.title } },
                            { id: 'programme_location', title: 'Program location', data: ( item, ctx ) => { return item?.programmeLocation?.title } }
                        ]
                    }
                },
                {
                    id      : 'recruiter', group: 'contract', label: 'Agency',
                    tooltip : { title: 'Agency', description: 'The agency of the contract' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruiter_asc', desc: 'recruiter_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'recruiter', title: 'Agency', data: ( item, ctx ) => { return item?.recruiter?.organization?.name } },
                            { id: 'recruiter_user', title: 'Agency user', data: ( item, ctx ) => { return item?.recruiter?.name } },
                        ]
                    }
                },
                {
                    id      : 'employer', group: 'contract', label: 'Job manager',
                    tooltip : { title: 'Job manager', description: 'The employer of the contract' },
                    scope   : [ 'admin', 'recruiter', 'recruiter' ],
                    //sort   : { asc: 'employer_asc', desc: 'employer_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'employer', title: 'Job manager', data: ( item, ctx ) => { return item?.employers?.[0]?.name } },
                            { id: 'employer_organization', title: 'Job manager organization', data: ( item, ctx ) => { return item?.employers?.[0]?.organization?.name } },
                        ]
                    }
                },
                {
                    id      : 'account_manager', group: 'contract', label: 'Account manager',
                    tooltip : { title: 'Account manager', description: 'The account manager of the contract' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'accountManager_asc', desc: 'accountManager_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'account_manager', title: 'Account manager', data: ( item, ctx ) => { return item?.contacts?.accountManagers?.[0]?.name } },
                            { id: 'account_manager_organization', title: 'Account manager organization', data: ( item, ctx ) => { return item?.contacts?.accountManagers?.[0]?.organization?.name } }
                        ]
                    }
                },
                {
                    id      : 'salary', group: 'contract', label: 'Rate',
                    tooltip : { title: 'Rate', description: 'The rate of the contract' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'salary_asc', desc: 'salary_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'salary', title: 'Rate', data: ( item, ctx ) =>
                                {
                                    if( item.employment?.salary?.amount )
                                    {
                                        return Commercials.convertCurrency( item.employment.salary.amount, item.employment.salary.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed( 2 );
                                    }
                                }
                            },
                            {
                                id: 'salary_currency', title: 'Rate currency', data: ( item, ctx ) =>
                                {
                                    if( item.employment?.salary?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'salary_rate', title: 'Rate rate', data: ( item, ctx ) =>
                                {
                                    if( item?.employment?.salary?.rate )
                                    {
                                        return item.employment.salary.rate;
                                    }
                                }
                            }
                        ]
                    }
                },
                /*
                {
                    id      : 'recruitment_fee', group: 'contract', label: 'Agency fee',
                    tooltip : { title: 'Agency fee', description: 'The agency fee of the contract' },
                    align   : 'right',
                    scope   : [ 'employer', 'recruiter' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee', title: 'Agency fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.recruitment.amount.amount, item.summary.fees.recruitment.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.recruitment.amount.markup;
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'agency_recruitment_fee', group: 'contract', label: 'Agency fee ( agency )',
                    tooltip : { title: 'Agency fee', description: 'The agency fee of the contract' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'agencyRecruitment_fee', title: 'Agency fee amount ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.agencyRecruitment.amount.amount, item.summary.fees.agencyRecruitment.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'agencyRecruitment_fee_currency', title: 'Agency fee currency ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'agencyRecruitment_fee_percentage', title: 'Agency fee in % ( agency )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agencyRecruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.agencyRecruitment.amount.markup;
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'employer_recruitment_fee', group: 'contract', label: 'Agency fee ( employer )',
                    tooltip : { title: 'Agency fee', description: 'The agency fee of the contract' },
                    align   : 'right',
                    scope   : [ 'admin' ],
                    //sort   : { asc: 'recruitmentFee_asc', desc: 'recruitmentFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'recruitment_fee', title: 'Agency fee amount ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.recruitment.amount.amount, item.summary.fees.recruitment.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_currency', title: 'Agency fee currency ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'recruitment_fee_percentage', title: 'Agency fee in % ( employer )', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.recruitment?.amount?.amount )
                                    {
                                        return item.summary.fees.recruitment.amount.markup;
                                    }
                                }
                            },
                        ]
                    }
                },
                {
                    id      : 'supplier_fee', group: 'contract', label: 'Agency handling fee',
                    tooltip : { title: 'Agency handling fee', description: 'The agency handling fee of the contract' },
                    align   : 'right',
                    scope   : [ 'admin', 'recruiter' ],
                    //sort   : { asc: 'supplierFee_asc', desc: 'supplierFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'supplier_fee', title: 'Agency handling fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.agency.amount.amount, item.summary.fees.agency.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'supplier_fee_currency', title: 'Agency handling fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'supplier_fee_percentage', title: 'Agency handling fee in %', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.agency?.amount?.amount )
                                    {
                                        return item.summary.fees.agency.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'employer_fee', group: 'contract', label: 'Employer handling fee',
                    tooltip : { title: 'Employer handling fee', description: 'The employer handling fee of the contract' },
                    align   : 'right',
                    scope   : [ 'admin', 'employer' ],
                    //sort   : { asc: 'employerFee_asc', desc: 'employerFee_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'employer_fee', title: 'Employer handling fee amount', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.employer?.amount?.amount )
                                    {
                                        return Commercials.convertCurrency( item.summary.fees.employer.amount.amount, item.summary.fees.employer.amount.currency, ctx?.$defaultCurrency || item.employment.salary.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'employer_fee_currency', title: 'Employer handling fee currency', data: ( item, ctx ) =>
                                {
                                    if( item.summary?.fees?.employer?.amount?.amount )
                                    {
                                        return ( ctx?.$defaultCurrency || item.employment.salary.currency );
                                    }
                                }
                            },
                            {
                                id: 'employer_fee_percentage', title: 'Employer handling fee in %', data: ( item, ctx ) =>
                                {
                                    if( item?.summary?.fees?.employer?.amount?.markup )
                                    {
                                        return item.summary.fees.employer.amount.markup;
                                    }
                                }
                            }
                        ]
                    }
                },
                */
                /*
                {
                    id     : 'employer_billing_entity', group: 'placement', label: 'Employer billing entity',
                    tooltip: '<b>Hover</b> on any column to get <b>detailed info</b>!',
                    scope  : [ 'admin', 'employer' ],
                    //sort   : { asc: 'employerBillingEntity_asc', desc: 'employerBillingEntity_desc' },
                    export :
                    {
                        columns: [] // TODO
                    }
                },
                {
                    id     : 'invoices', group: 'contract', label: 'Invoices',
                    tooltip: '<b>Hover</b> on any column to get <b>detailed info</b>!',
                    scope  : [ 'admin', 'employer', 'recruiter' ],
                    //sort   : { asc: 'invoices_asc', desc: 'invoices_desc' },
                    export :
                    {
                        columns:
                        [
                            { id: 'invoices', title: 'Invoices', data: ( item, ctx ) => { return item?.invoices?.length } }
                        ]
                    }
                },
                */
                {
                    id      : 'documents', group: 'contract', label: 'Documents',
                    tooltip : { title: 'Documents', description: 'The documents of the contract' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'documents_asc', desc: 'documents_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'documents', title: 'Documents', data: ( item, ctx ) => { return item?.documents?.length } }
                        ]
                    }
                },
                {
                    id      : 'start_date', group: 'contract', label: 'Start date',
                    tooltip : { title: 'Start date', description: 'The start date of the contract' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'startDate_asc', desc: 'startDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'start_date', title: 'Start date', data: ( item, ctx ) =>
                                {
                                    if( item?.employment?.interval?.start )
                                    {
                                        return new Date( item.employment.interval.start ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'end_date', group: 'contract', label: 'End date',
                    tooltip : { title: 'End date', description: 'The end date of the contract' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'endDate_asc', desc: 'endDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'end_date', title: 'End date', data: ( item, ctx ) =>
                                {
                                    if( item?.employment?.interval?.end )
                                    {
                                        return new Date( item.employment.interval.end ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'updated_date', group: 'contract', label: 'Updated date',
                    tooltip : { title: 'Updated date', description: 'The date when the contract was last updated' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'updatedDate_asc', desc: 'updatedDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'updated_date', title: 'Updated date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.updated )
                                    {
                                        return new Date( item.events.updated ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'created_date', group: 'contract', label: 'Created date',
                    tooltip : { title: 'Created date', description: 'The date when the contract was created' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'createdDate_asc', desc: 'createdDate_desc' },
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created_date', title: 'Created date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'admin', name: 'default', columns: [ "title", "status", "location", "salary", /*"agency_recruitment_fee", "employer_recruitment_fee", "supplier_fee", "employer_fee",*/ "recruiter", "documents", "start_date", "end_date" ] },
                { id: 'employer', name: 'default', columns: [ "title", "status", "location", "salary", /*"recruitment_fee", "employer_fee",*/ "documents", "recruiter", "start_date", "end_date" ] },
                { id: 'recruiter', name: 'default', columns: [ "title", "status", "salary", /*"recruitment_fee", "supplier_fee",*/ "documents", "start_date", "end_date" ] }
            ]
        }
    },

    timesheetList: //DONE
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'timesheet', label: 'Timesheet' },
            ],

            columns:
            [
                {
                    id      : 'interval', group: 'timesheet', label: 'Interval',
                    tooltip : { title: 'Interval', description: 'The interval of the timesheet' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'interval', title: 'Interval', data: ( item, ctx ) => { return `${ new Date( item.interval.start ).toUTCDate() } - ${ new Date( item.interval.end ).toUTCDate() }`; } }
                        ]
                    }
                },
                {
                    id      : 'duration', group: 'timesheet', label: 'Duration',
                    tooltip : { title: 'Duration', description: 'The duration of the timesheet' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'duration', title: 'Duration', data: ( item, ctx ) => { return `${item.duration.value} ${item.duration.unit}`; } }
                        ]
                    }
                },
                {
                    id      : 'rate', group: 'timesheet', label: 'Rate',
                    tooltip : { title: 'Rate', description: 'The rate of the timesheet' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'rate', title: 'Rate', data: ( item, ctx ) => { return Commercials.convertCurrency( item.salary / item.duration.value, ctx.Detail.employment.salary.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed( 2 ); } },
                            { id: 'rate_currency', title: 'Rate currency', data: ( item, ctx ) => { return ( ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ); } }
                        ]
                    }
                },
                {
                    id      : 'salary', group: 'timesheet', label: 'Salary',
                    tooltip : { title: 'Salary', description: 'The salary of the timesheet' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'salary', title: 'Salary', data: ( item, ctx ) => { return Commercials.convertCurrency( item.salary, ctx.Detail.employment.salary.currency, ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ).toFixed( 2 ) } },
                            { id: 'salary_currency', title: 'Salary currency', data: ( item, ctx ) => { return ( ctx?.$defaultCurrency || ctx.Detail.employment.salary.currency ) } },
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'interval', 'duration', 'rate', 'salary' ] }
            ]
        }
    },

    documentList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'document', label: 'Document' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'document', label: 'Name',
                    tooltip : { title: 'Name', description: 'The title of the document' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'title', title: 'Name', data: ( item, ctx ) => { return item?.title; } }
                        ]
                    }
                },
                {
                    id      : 'type', group: 'document', label: 'Type',
                    tooltip : { title: 'Type', description: 'The type of the document' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'type', title: 'File type', data: ( item, ctx ) => { return item.type; } },
                        ]
                    }
                },
                {
                    id      : 'author', group: 'document', label: 'Uploaded by',
                    tooltip : { title: 'Uploaded by', description: 'The author of the document' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'size', title: 'Uploader name', data: ( item, ctx ) => { return item?.uploaderAccount?.name; } },
                            { id: 'mime', title: 'Uploader company', data: ( item, ctx ) => { return item?.uploaderAccount?.organization?.name; } }
                        ]
                    }
                },
                {
                    id      : 'size', group: 'document', label: 'Mime/size',
                    tooltip : { title: 'Mime/size', description: 'The mime type and size of the document' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'size', title: 'File size', data: ( item, ctx ) => { return Format.fileSize( item?.size, true ); } },
                            { id: 'mime', title: 'Mime type', data: ( item, ctx ) => { return item?.mime } }
                        ]
                    }
                },
                {
                    id      : 'created_date', group: 'document', label: 'Created date',
                    tooltip : { title: 'Created date', description: 'The date when the document was created' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created_date', title: 'Created date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'type', 'author', 'size', 'created_date' ] }
            ]
        }
    },

    invoiceList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'invoice', label: 'Invoice' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'invoice', label: 'Invoice no.',
                    tooltip : { title: 'Invoice no.', description: 'The title of the invoice' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'invoice_no', title: 'Invoice no.', data: ( item, ctx ) => { return item?.invoiceNumber; } },
                            { id: 'invoice_reference', title: 'Invoice reference', data: ( item, ctx ) => { return item?.reference; } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'invoice', label: 'Status',
                    tooltip : { title: 'Status', description: 'The status of the invoice' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Status', data: ( item, ctx ) => { return item?.status; } }
                        ]
                    }
                },
                {
                    id      : 'amount', group: 'invoice', label: 'Amount',
                    tooltip : { title: 'Amount', description: 'The amount of the invoice' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'amount', title: 'Amount', data: ( item, ctx ) =>
                                {
                                    if( item?.amount?.total )
                                    {
                                        return Commercials.convertCurrency( item.amount.total, item.amount.currency, ctx?.$defaultCurrency || item.amount.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'currency', title: 'Currency', data: ( item, ctx ) =>
                                {
                                    if( item?.amount?.total )
                                    {
                                        return ( ctx?.$defaultCurrency || item.amount.currency );
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'tax', group: 'invoice', label: 'TAX',
                    tooltip : { title: 'TAX', description: 'The tax of the invoice' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'amount', title: 'Amount', data: ( item, ctx ) =>
                                {
                                    if( item?.amount?.tax )
                                    {
                                        return Commercials.convertCurrency( item.amount.tax, item.amount.currency, ctx?.$defaultCurrency || item.amount.currency ).toFixed(2);
                                    }
                                }
                            },
                            {
                                id: 'currency', title: 'Currency', data: ( item, ctx ) =>
                                {
                                    if( item?.amount?.total )
                                    {
                                        return ( ctx?.$defaultCurrency || item.amount.currency );
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'created_date', group: 'invoice', label: 'Invoice date',
                    tooltip : { title: 'Invoice date', description: 'The date when the invoice was created' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created_date', title: 'Invoice date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    id      : 'due_date', group: 'invoice', label: 'Due date',
                    tooltip : { title: 'Due date', description: 'The due date of the invoice' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'due_date', title: 'Due date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.due )
                                    {
                                        return new Date( item.events.due ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'amount', 'tax', 'created_date', 'due_date', 'status' ] }
            ]
        }
    },

    contactList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups: [],

            columns: [],

            presets:
            [
                { id: 'default', name: 'default', columns: [] }
            ]
        }
    },

    exceptionList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'exception', label: 'Exception' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'exception', label: 'Name',
                    tooltip : { title: 'Name', description: 'The title of the exception' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'title', title: 'Name', data: ( item, ctx ) => { return item?.name; } }
                        ]
                    }
                },
                {
                    id      : 'created_date', group: 'exception', label: 'Created date',
                    tooltip : { title: 'Created date', description: 'The date when the exception was created' },
                    width   : '192px',
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created_date', title: 'Created date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'created_date' ] }
            ]
        }
    },

    exclusionList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'exception', label: 'Exception' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'exception', label: 'Name',
                    tooltip : { title: 'Name', description: 'The title of the exception' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'title', title: 'Name', data: ( item, ctx ) => { return item?.name; } }
                        ]
                    }
                },
                {
                    id      : 'created_date', group: 'exception', label: 'Created date',
                    tooltip : { title: 'Created date', description: 'The date when the exception was created' },
                    width   : '192px',
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created_date', title: 'Created date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                },
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title', 'created_date' ] }
            ]
        }
    },

    accessInvitationList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'invitation', label: 'Invitation' },
            ],

            columns:
            [
                {
                    id      : 'name', group: 'invitation', label: 'Name',
                    tooltip : { title: 'Name', description: 'The name of the invitation' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'name', title: 'Name', data: ( item, ctx ) => { return item?.name; } }
                        ]
                    }
                },
                {
                    id      : 'inviter', group: 'invitation', label: 'Inviter',
                    tooltip : { title: 'Inviter', description: 'The inviter of the invitation' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'inviter', title: 'Inviter', data: ( item, ctx ) => { return item?.inviter?.name; } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'invitation', label: 'Status',
                    tooltip : { title: 'Status', description: 'The status of the invitation' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Status', data: ( item, ctx ) => { return item?.status; } }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'name', 'inviter', 'status' ] }
            ]
        }
    },

    companyUsersList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'user', label: 'Company user' },
            ],

            columns:
            [
                {
                    id      : 'name', group: 'user', label: 'Name',
                    tooltip : { title: 'Name', description: 'The name of the user' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'name', title: 'Name', data: ( item, ctx ) => { return item?.name; } }
                        ]
                    }
                },
                {
                    id      : 'permission', group: 'user', label: 'Permissions',
                    tooltip : { title: 'Permissions', description: 'The permissions of the user' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'permission', title: 'Permissions', data: ( item, ctx ) => { return 'TODO'; } }
                        ]
                    }
                },
                {
                    id      : 'inviter', group: 'user', label: 'Inviter',
                    tooltip : { title: 'Inviter', description: 'The inviter of the user' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'inviter', title: 'Inviter', data: ( item, ctx ) => { return item?.inviter?.name; } }
                        ]
                    }
                },
                {
                    id      : 'status', group: 'user', label: 'Status',
                    tooltip : { title: 'Status', description: 'The status of the invitation' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            { id: 'status', title: 'Status', data: ( item, ctx ) => { return item?.status; } }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'name', 'inviter', 'permission', 'status' ] }
            ]
        }
    },

    tagList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'tags', label: 'Tags' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'tags', label: 'Name',
                    tooltip : { title: 'Name', description: 'Name of tag' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                },
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'title' ] }
            ]
        }
    },

    billingEntityList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'billingEntity', label: 'Billing entity' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'billingEntity', label: 'Name',
                    tooltip : { title: 'Name', description: 'The title of the billing entity' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                },
                {
                    id      : 'crn', group: 'billingEntity', label: 'CRN',
                    tooltip : { title: 'CRN', description: 'The company registration number of the billing entity' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                },
                {
                    id      : 'tax', group: 'billingEntity', label: 'TAX',
                    tooltip : { title: 'TAX', description: 'The tax number of the billing entity' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                },
                {
                    id      : 'vat', group: 'billingEntity', label: 'VAT',
                    tooltip : { title: 'VAT', description: 'The VAT number of the billing entity' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                },
                /*
                {
                    id      : 'locations', group: 'billingEntity', label: 'Billable countries',
                    tooltip : { title: 'Billable countries', description: 'The countries where the billing entity is billable' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                }
                */
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: ['title','crn','tax','vat'/*,'locations'*/] }
            ]
        }
    },

    invoicingContactList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'invoicingContact', label: 'Invoicing contact' },
            ],

            columns:
            [
                {
                    id      : 'title', group: 'invoicingContact', label: 'Name',
                    tooltip : { title: 'Name', description: 'The title of the invoicing contact' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                },
                {
                    id      : 'email', group: 'invoicingContact', label: 'Email',
                    tooltip : { title: 'Email', description: 'The email of the invoicing contact' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                },
                {
                    id      : 'phone', group: 'invoicingContact', label: 'Phone',
                    tooltip : { title: 'Phone', description: 'The phone of the invoicing contact' },
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: ['title', 'email', 'phone'] }
            ]
        }
    },

    noteList:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'note', label: 'Note' },
            ],

            columns:
            [
                {
                    id      : 'creator', group: 'note', label: 'Creator',
                    width   : '56px',
                    tooltip : { title: 'Creator', description: 'The name of the creator of the note' },
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'relation', group: 'note', label: 'Relation',
                    width   : '72px',
                    tooltip : { title: 'Relation', description: 'The relation of the note' },
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'note', group: 'note', label: 'Note',
                    tooltip : { title: 'Note', description: 'The content of the note' },
                    scope   : [ 'admin', 'employer', 'recruiter' ]
                },
                {
                    id      : 'created_date', group: 'note', label: 'Created date',
                    width   : '48px',
                    tooltip : { title: 'Created date', description: 'The date when the note was created' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    export  :
                    {
                        columns:
                        [
                            {
                                id: 'created_date', title: 'Created date', data: ( item, ctx ) =>
                                {
                                    if( item?.events?.created )
                                    {
                                        return new Date( item.events.created ).toUTCDate();
                                    }
                                }
                            }
                        ]
                    }
                }
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'creator', 'relation', 'note', 'created_date' ] }
            ]
        }
    },

    insightsMap:
    {
        grid    : { columns: 4, gap: '24px' },
        table   :
        {
            groups:
            [
                { id: 'jobs', label: 'Jobs' },
            ],

            columns:
            [
                {
                    id      : 'country', group: 'jobs',  label: 'Country',
                    tooltip : { title: 'Country', description: 'The country of the job' },
                    width   : '256px',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'location_asc', desc: 'location_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'country', title: 'Country', data: ( item, ctx ) => { return item?.location.name } },
                            { id: 'currency', title: 'Currency', data: ( item, ctx ) => { return ctx.$defaultCurrency || 'GBP' } },
                        ]
                    }
                },
                {
                    id      : 'junior', group: 'jobs', label: 'Junior',
                    tooltip : { title: 'Junior', description: 'The junior salary of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'junior_asc', desc: 'junior_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'junior-min', title: 'Junior min', data: ( item, ctx ) =>
                            { 
                                return Math.round( Commercials.convertCurrency( item?.overall[10].base, item.currency, ctx.$defaultCurrency || 'GBP' ) ) } 
                            },
                            { id: 'junior-max', title: 'Junior max', data: ( item, ctx ) =>
                            { 
                                const p10 = item?.overall[10].base;
                                const p50 = item?.overall[50].base;
                                return Math.round( Commercials.convertCurrency( ( p10 + 2 * p50 ) / 3, item.currency, ctx.$defaultCurrency || 'GBP' ) ) } 
                            },
                        ]
                    }
                },
                {
                    id      : 'intermediate', group: 'jobs', label: 'Intermediate',
                    tooltip : { title: 'Intermediate', description: 'The intermediate salary of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'intermediate_asc', desc: 'intermediate_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'intermediate-min', title: 'Intermediate min', data: ( item, ctx ) =>
                            { 
                                return Math.round( Commercials.convertCurrency( item?.overall[50].base, item.currency, ctx.$defaultCurrency || 'GBP' ) ) } 
                            },
                            { id: 'intermediate-max', title: 'Intermediate max', data: ( item, ctx ) =>
                            { 
                                const p50 = item?.overall[50].base;
                                const p75 = item?.overall[75].base;
                                return Math.round( Commercials.convertCurrency( ( p50 + 2 * p75 ) / 3, item.currency, ctx.$defaultCurrency || 'GBP' ) ) } 
                            },
                        ]
                    }
                },
                {
                    id      : 'senior', group: 'jobs', label: 'Senior',
                    tooltip : { title: 'Senior', description: 'The senior salary of the job' },
                    align   : 'center',
                    scope   : [ 'admin', 'employer', 'recruiter' ],
                    sort    : { asc: 'senior_asc', desc: 'senior_desc' },
                    export  :
                    {
                        columns:
                        [
                            { id: 'senior-min', title: 'Senior min', data: ( item, ctx ) =>
                            { 
                                const p75 = item?.overall[75].base;
                                const p90 = item?.overall[90].base;
                                return Math.round( Commercials.convertCurrency( ( p75 + p90 ) / 2, item.currency, ctx.$defaultCurrency || 'GBP' ) ) } 
                            },
                            { id: 'senior-max', title: 'Senior max', data: ( item, ctx ) =>
                            { 
                                const p75 = item?.overall[75].base;
                                const p90 = item?.overall[90].base;
                                return Math.round( Commercials.convertCurrency( ( 4 * p90 - p75 ) / 3, item.currency, ctx.$defaultCurrency || 'GBP' ) ) } 
                            },
                        ]
                    }
                }
                /*{
                    id     : '#25', group: 'jobs', label: '25% percentile', align: 'center',
                    scope  : [ 'admin', 'employer', 'recruiter' ],
                    sort   : { asc: '25%_asc', desc: '25%_desc' },
                    export :
                    {
                        columns:
                        [
                            { id: '#25', title: '25% percentile', data: ( item, ctx ) => { return Commercials.convertCurrency( item?.overall[25].base, item.currency, ctx.$defaultCurrency || 'GBP' ).toFixed(2) } },
                        ]
                    }
                },
                {
                    id     : '#75', group: 'jobs', label: '75% percentile', align: 'center',
                    scope  : [ 'admin', 'employer', 'recruiter' ],
                    sort   : { asc: '75%_asc', desc: '75%_desc' },
                    export :
                    {
                        columns:
                        [
                            { id: '#75', title: '75% percentile', data: ( item, ctx ) => { return Commercials.convertCurrency( item?.overall[75].base, item.currency, ctx.$defaultCurrency || 'GBP' ).toFixed(2) } },
                        ]
                    }
                },*/
               
            ],

            presets:
            [
                { id: 'default', name: 'default', columns: [ 'country', 'junior', 'intermediate', 'senior' ] }
            ]
        }
    },
}

export const TablePreset =
{
    default( uid, listName, store )
    {
        if( $_TableObject.hasOwnProperty( listName ) )
        {
            let $_tablePresets = [];

            let $_permission = store.getters['auth/user/permissions']();

            if( !$_TableObject[ listName ].table.presets.find( p => p.id === uid ) )
            {
                for( let preset of $_TableObject[ listName ].table.presets.filter( p => [ 'admin', 'recruiter', 'employer' ].includes( p.id ) && $_permission.includes( p.id ) ) )
                {
                    $_tablePresets.push(
                    {
                        id      : preset.id,
                        name    : preset.name,
                        active  : true,
                        columns : preset.columns.map( column =>
                        {
                            let $_column = $_TableObject[ listName ].table.columns.find( c => c.id === column );

                            return ({ id: $_column.id, width: $_column.width })
                        })
                    })
                }
            }

            if( !$_tablePresets.length )
            {
                let $_defaultPreset = $_TableObject[ listName ].table.presets.find( p => p.id === uid );

                if( !$_defaultPreset )
                {
                    $_defaultPreset = $_TableObject[ listName ].table.presets.find( p => p.id === 'default' );
                }

                $_tablePresets.push(
                {
                    id      : $_defaultPreset.id,
                    name    : $_defaultPreset.name,
                    active  : true,
                    columns : $_defaultPreset.columns.map( column =>
                    {
                        let $_column = $_TableObject[ listName ].table.columns.find( c => c.id === column );

                        return ({ id: $_column.id, width: $_column.width })
                    })
                })
            }

            return JSON.stringify({ presets: $_tablePresets });
        }
        else
        {
            console.error( `TABLE:defaultPreset => Table object with name '${listName}' does not exist!` );
        }
    },

    create( settings, ctx )
    {
        settings.presets.push( ctx.preset );

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));
    },

    update( settings, ctx )
    {
        ctx.preset.active && ( ctx.isLoaded.value = false );

        setTimeout( () =>
        {
            let currPresetIndex = settings.presets.findIndex( p => p.id === ctx.preset.id );

            settings.presets[currPresetIndex] = ctx.preset;

            Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));

            ctx.preset.active && ( ctx.isLoaded.value = true );

        }, 500 );
    },

    delete( settings, ctx )
    {
        let currPresetIndex = settings.presets.findIndex( p => p.id === ctx.id );

        settings.presets.splice( currPresetIndex, 1 );

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));
    },

    change( settings, ctx )
    {
        ctx.isLoaded.value = false;

        setTimeout( () =>
        {
            for( let preset of settings.presets )
            {
                preset.active = preset.id === ctx.id;
            }

            let customPreset = settings.presets.find( p => p.id === 'custom' );

            if( customPreset && !customPreset.active )
            {
                settings.presets = settings.presets.filter( p => p.id !== 'custom' );
            }

            Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));

            ctx.isLoaded.value = true;

        }, 500 );
    }
}

export const TableColumn =
{
    changeSize( settings, ctx )
    {
        let currPreset = settings.presets.find( f => f.active );
        let currColumn = currPreset.columns.find( c => c.id === ctx.column.id );

        currPreset.width = ctx.preset.width;
        currColumn.width = ctx.column.width;

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));
    },

    resetSize( settings, ctx )
    {
        let currPreset = settings.presets.find( f => f.active );

        for( let column of currPreset.columns )
        {
            delete column.width;
        }

        delete currPreset.width;

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));
    }
}

export const TableSelect =
{
    get defaultSelectSettings()
    {
        return { allSelected: false, selected: [] };
    },

    toggleBulkSelect( ListData, ctx )
    {
        if( !Helpers.Storage(undefined, 'get', ctx.storagePath ) )
        {
            Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( this.defaultSelectSettings ));
        }

        let storage = JSON.parse( Helpers.Storage( undefined, 'get', ctx.storagePath ) );

        if( storage.selected.includes( ctx.key ) )
        {
            storage.selected = storage.selected.filter( item => item !== ctx.key );
        }
        else
        {
            storage.selected.push( ctx.key );
        }

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( storage ));

        Helpers.ObjectSet( ListData, 'selectedItems', storage );
    },

    setBulkSelect( ListData, ctx )
    {
        if( !Helpers.Storage(undefined, 'get', ctx.storagePath ) )
        {
            Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( this.defaultSelectSettings ));
        }

        let storage = JSON.parse( Helpers.Storage(undefined, 'get', ctx.storagePath ) );

        if( ctx.allSelected )
        {
            storage.selected = [];
        }
        else
        {
            storage.selected = ListData.items.map( item => Helpers.ObjectGet( item, ctx.key ) );
        }

        storage.allSelected = ctx.allSelected;

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( storage ));

        Helpers.ObjectSet( ListData, 'selectedItems', storage );
    },

    clearBulkSelect( ListData, ctx )
    {
        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( this.defaultSelectSettings ));

        Helpers.ObjectSet( ListData, 'selectedItems', this.defaultSelectSettings );
    }
}

export const TableActions =
{
    'jobList': ( sections = [ 'header', 'bulk', 'quick' ], $permission, $router, $dialogs, $swagger, fetchItemList, $draft ) =>
    {
        let actionList =
        {
            header:
            [
                {
                    icon      : 'isax-add',
                    label     : 'Create requisition' + ( $draft && $draft.get('job') ? ' ( draft )' : '' ),
                    class     : 'pulse-animation' + ( $draft && $draft.get('job') ? ' success' : '' ),
                    condition : ( ctx ) => { return $permission.isVisible( 'job-create', 'job-list' ) },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'JobModal',
                        ctx         : { method: 'create', fetchList: async () => await fetchItemList() },
                        component   : () => import('@/templates/views/recruitment/jobs/dialogs/JobDialog')
                    }
                }
            ],

            bulk: [],

            quick:
            [
                {
                    icon      : 'isax-search-status',
                    label     : 'Details',
                    children  :
                    [
                        {
                            icon      : 'isax-eye',
                            label     : 'Show details',
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push( { name: 'JobDetail', params: { jobID: item.id } } ) },
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-edit-2',
                    label     : 'Modification',
                    condition : ( job, ctx ) =>
                    {
                        return $permission.isVisible( 'job-status-open', 'job-list', job ) ||
                               $permission.isVisible( 'job-status-pause', 'job-list', job ) ||
                               $permission.isVisible( 'job-status-close', 'job-list', job ) ||
                               $permission.isVisible( 'job-edit', 'job-list', job ) ||
                               $permission.isVisible( 'job-duplicate', 'job-list', job )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-notification-status4',
                            label     : 'Change status',
                            condition : ( job, ctx ) =>
                            {
                                return $permission.isVisible( 'job-status-open', 'job-list', job ) ||
                                       $permission.isVisible( 'job-status-pause', 'job-list', job ) ||
                                       $permission.isVisible( 'job-status-close', 'job-list', job )
                            },
                            children  :
                            [
                                {
                                    icon      : 'isax-play-circle',
                                    label     : 'Open',
                                    condition : ( job, ctx ) => { return $permission.isVisible( 'job-status-open', 'job-list', job ) },
                                    action    :
                                    {
                                        type      : 'modal',
                                        id        : 'StatusModal',
                                        ctx       : { method: 'open', fetchList: async () => await fetchItemList() },
                                        transform : ( store, item ) => { return { id: item.id } },
                                        component : () => import('@/templates/views/recruitment/jobs/dialogs/edit/JobStatusDialog.vue')
                                    }
                                },
                                {
                                    icon      : 'isax-pause-circle',
                                    label     : 'On hold',
                                    condition : ( job, ctx ) => { return $permission.isVisible( 'job-status-pause', 'job-list', job ) },
                                    action    :
                                    {
                                        type        : 'modal',
                                        id          : 'StatusModal',
                                        ctx         : { method: 'paused', fetchList: async () => await fetchItemList() },
                                        transform   : ( store, item ) => { return { id: item.id } },
                                        component   : () => import('@/templates/views/recruitment/jobs/dialogs/edit/JobStatusDialog.vue')
                                    }
                                },
                                {
                                    icon      : 'isax-stop-circle',
                                    label     : 'Close',
                                    condition : ( job, ctx ) => { return $permission.isVisible( 'job-status-close', 'job-list', job ) },
                                    action    :
                                    {
                                        type        : 'modal',
                                        id          : 'StatusModal',
                                        ctx         : { method: 'closed', fetchList: async () => await fetchItemList() },
                                        transform   : ( store, item ) => { return { id: item.id } },
                                        component   : () => import('@/templates/views/recruitment/jobs/dialogs/edit/JobStatusDialog.vue')
                                    }
                                }
                            ]
                        },
                        {
                            icon      : 'isax-edit',
                            label     : 'Edit',
                            condition : ( job, ctx ) => { return $permission.isVisible( 'job-edit', 'job-list', job ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'JobModal',
                                ctx         : { method: 'edit', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/jobs/dialogs/JobDialog')
                            }
                        },
                        {
                            icon      : 'isax-copy',
                            label     : 'Duplicate',
                            condition : ( job, ctx ) => { return $permission.isVisible( 'job-duplicate', 'job-list', job ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'JobModal',
                                ctx         : { method: 'duplicate' },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/jobs/dialogs/JobDialog')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-next',
                    label     : 'Actions',
                    condition : ( job, ctx ) =>
                    {
                        return $permission.isVisible( 'job-invitation-create', 'job-list', job ) ||
                               $permission.isVisible( 'application-create', 'job-list', { job } ) ||
                               $permission.isVisible( 'job-briefing-edit', 'job-list', { job, briefing: undefined } )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-cloud-add4',
                            label     : 'Invite agencies',
                            condition : ( job, ctx ) => { return $permission.isVisible( 'job-invitation-create', 'job-list', job ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'AgencyInvitationModal',
                                ctx         : { fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/jobs/dialogs/invitation/JobAgencyInvitationDialog.vue')
                            }
                        },
                        {
                            icon      : 'isax-edit',
                            label     : 'Submit application',
                            condition : ( job, ctx ) => { return $permission.isVisible( 'application-create', 'job-list', { job } ) },
                            action    :
                            {
                                type      : 'modal',
                                id        : 'ApplicationModal',
                                ctx       : { method: 'create', fetchList: async () => await fetchItemList() },
                                transform : ( store, item ) => { return { job: item } },
                                component : () => import('@/templates/views/recruitment/applications/dialogs/ApplicationDialog')
                            }
                        },
                        {
                            icon      : 'isax-call-add',
                            label     : 'Schedule briefing call',
                            condition : ( job, ctx ) => { return $permission.isVisible( 'job-briefing-edit', 'job-list', { job, briefing: undefined } ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ScheduleBriefingCallModal',
                                ctx         : { method: 'create', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { job: item, briefing: undefined } },
                                component   : () => import('@/templates/views/recruitment/jobs/dialogs/briefing/JobScheduleBriefingCall.vue')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-sms',
                    label     : 'Send message',
                    condition : ( job, ctx ) => { return $permission.isVisible( 'message-employer', 'job-list', job ) || $permission.isVisible( 'message-account-manager', 'job-list', job ) },
                    children  :
                    [
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Job manager',
                            condition : ( job, ctx ) => { return $permission.isVisible( 'message-employer', 'job-list', job ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/jobs/${item.id}/create?currentTab=JobMessages${item.employers.map( e => '&recipient=' + e.id ).join('')}`) },
                            }
                        },
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Account manager',
                            condition : ( job, ctx ) => { return $permission.isVisible( 'message-account-manager', 'job-list', job ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/jobs/${item.id}/create?currentTab=JobMessages${item.accountManagers.map( e => '&recipient=' + e.id ).join('')}`) },
                            }
                        },
                    ]
                },

                {
                    type: 'divider',
                    condition : ( job, ctx ) => { return $permission.isVisible( 'job-engagement-disengage', 'job-list', job ) || $permission.isVisible( 'job-delete', 'job-list', job ) },
                },

                {
                    icon      : 'isax-clipboard-close',
                    label     : 'Disengage',
                    condition : ( job, ctx ) => { return $permission.isVisible( 'job-engagement-disengage', 'job-list', job ) },
                    action    :
                    {
                        type      : 'modal',
                        id        : 'EngagementModal',
                        ctx       : { fetchList: async () => await fetchItemList() },
                        transform : ( store, item ) => { return { engagementID: item.$actions.disengageID, jobID: item.id } },
                        component : () => import('@/templates/views/recruitment/jobs/dialogs/engagement/JobDisengageDialog.vue')
                    }
                },
                {
                    icon      : 'isax-trash',
                    label     : 'Delete job',
                    condition : ( job, ctx ) => { return $permission.isVisible( 'job-delete', 'job-list', job ) },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'AreYouSureModal',
                        props       :
                        {
                            title          : 'Confirm job posting deletion',
                            description    : 'You are about to <b>permanently delete</b> this job posting. <br/><br/> Deleting this job posting is final and cannot be undone. Please confirm that you want to proceed with this action.',
                            handleCallback : true,
                            callback       : async ( submitButton ) =>
                            {
                                await $swagger.delete_job( $dialogs['AreYouSureModal'].ctx.id );

                                submitButton.element.addEventListener('animationend', async () =>
                                {
                                    $dialogs['AreYouSureModal'].close(); await fetchItemList();

                                }, { once: true });
                            }
                        },
                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                    }
                }
            ]
        }

        if( !sections.includes('header') ) { delete actionList.header; }
        if( !sections.includes('bulk') )   { delete actionList.bulk; }
        if( !sections.includes('quick') )  { delete actionList.quick; }

        return actionList;
    },

    'applicationList': ( sections = [ 'header', 'bulk', 'quick' ], $permission, $router, $swagger, fetchItemList ) =>
    {
        let actionList =
        {
            header : [],
            bulk   : [],
            quick  :
            [
                {
                    icon      : 'isax-search-status',
                    label     : 'Details',
                    children  :
                    [
                        {
                            icon      : 'isax-eye',
                            label     : 'Show details',
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push( { name: 'ApplicationDetail', params: { applicationID: item.id } } ) },
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-edit-2',
                    label     : 'Modification',
                    condition : ( application, ctx ) => { return $permission.isVisible( 'application-edit', 'application-list', { application } ) || $permission.isVisible( 'application-duplicate', 'application-list', { application } ) },
                    children  :
                    [
                        {
                            icon      : 'isax-edit',
                            label     : 'Edit',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-edit', 'application-list', { application } ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationModal',
                                ctx         : { method: 'edit', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/ApplicationDialog')
                            }
                        },
                        {
                            icon      : 'isax-copy',
                            label     : 'Duplicate',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-duplicate', 'application-list', { application } ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationDuplicateModal',
                                ctx         : { method: 'duplicate' },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/duplicate/ApplicationDuplicateDialog')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-next',
                    label     : 'Progression',
                    condition : ( application, ctx ) =>
                    {
                        return $permission.isVisible( 'application-review', 'application-list', { application, job: application.job } ) ||
                               $permission.isVisible( 'application-interview', 'application-list', { application, job: application.job } ) ||
                               $permission.isVisible( 'application-offer', 'application-list', { application, job: application.job } ) ||
                               $permission.isVisible( 'application-offer-edit', 'application-list', { application, job: application.job } ) ||
                               $permission.isVisible( 'application-offer-confirm', 'application-list', { application, job: application.job } ) ||
                               $permission.isVisible( 'application-approve-start-date', 'application-list', { application, job: application.job } )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-flash-1',
                            label     : 'Reviewing',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-review', 'application-list', { application, job: application.job } ) }, //ctx.$permission.has(['admin','employer']) && ['open','paused'].includes( application.job.status ) && ["submitted"].includes( application.status )
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationReviewedModal',
                                ctx         : { fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationReviewedDialog')
                            }
                        },
                        {
                            icon      : 'isax-microphone-24',
                            label     : 'Request interview',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-interview', 'application-list', { application, job: application.job } ) }, //ctx.$permission.has(['admin','employer']) && ['open','paused'].includes( application.job.status ) && ["reviewed"].includes( application.status )
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationInterviewModal',
                                ctx         : { fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationInterviewDialog')
                            }
                        },
                        {
                            icon      : 'isax-flag4',
                            label     : 'Submit offer',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-offer', 'application-list', { application, job: application.job } ) }, //( ctx.$permission.has(['admin','employer']) && ['open','paused'].includes( application.job.status ) && ["interviewing"].includes( application.status ) ) || ( ctx.$permission.has(['recruiter','employer']) && ["under-offer"].includes( application.status ) && !application.hasOwnProperty('offer') )
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationOfferModal',
                                ctx         : { method: 'submit', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationOfferDialog')
                            }
                        },
                        {
                            icon      : 'isax-edit4',
                            label     : 'Edit offer',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-offer-edit', 'application-list', { application, job: application.job } ) }, //ctx.$permission.has(['admin','recruiter','employer']) && ['open','paused'].includes( application.job.status ) && ["under-offer"].includes( application.status ) && ( application.hasOwnProperty('offer') && application.offer?.proposer?.organization?.id === organizationID )
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationOfferModal',
                                ctx         : { method: 'submit', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationOfferDialog')
                            }
                        },
                        {
                            icon      : 'isax-money-tick',
                            label     : 'Confirm offer',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-offer-confirm', 'application-list', { application, job: application.job } ) }, //ctx.$permission.has(['recruiter','employer']) && ['open','paused'].includes( application.job.status ) && ["under-offer"].includes( application.status ) && application.hasOwnProperty('offer') && application?.offer?.proposer?.organization?.id !== organizationID
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationOfferModal',
                                ctx         : { method: 'confirm', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationOfferDialog')
                            }
                        },
                        {
                            icon      : 'isax-money-change',
                            label     : 'Counter offer',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-offer-confirm', 'application-list', { application, job: application.job } ) }, //ctx.$permission.has(['recruiter','employer']) && ['open','paused'].includes( application.job.status ) && ["under-offer"].includes( application.status ) && application.hasOwnProperty('offer') && application?.offer?.proposer?.organization?.id !== organizationID
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationOfferModal',
                                ctx         : { method: 'counter', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationOfferDialog')
                            }
                        },
                        {
                            icon      : 'isax-money-forbidden',
                            label     : 'Reject offer',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-offer-confirm', 'application-list', { application, job: application.job } ) }, //ctx.$permission.has(['recruiter','employer']) && ['open','paused'].includes( application.job.status ) && ["under-offer"].includes( application.status ) && application.hasOwnProperty('offer') && application?.offer?.proposer?.organization?.id !== organizationID
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationOfferModal',
                                ctx         : { method: 'reject', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationOfferDialog')
                            }
                        },
                        {
                            icon      : 'isax-timer-start',
                            label     : 'Confirm start date',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-approve-start-date', 'application-list', { application, job: application.job } ) }, //ctx.$permission.has(['admin','employer']) && ["placed"].includes( application.status )
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ApplicationStartModal',
                                ctx         : { method: 'confirm', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationStartDialog.vue')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-next',
                    label     : 'Actions',
                    condition : ( application, ctx ) => { return $permission.isVisible( 'application-briefing-edit', 'application-list', { application, briefing: undefined } ) },
                    children  :
                    [
                        {
                            icon      : 'isax-call-add',
                            label     : 'Schedule interview call',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'application-briefing-edit', 'application-list', { application, briefing: undefined } ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ScheduleInterviewCallModal',
                                ctx         : { method: 'create', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { application: item, briefing: undefined } },
                                component   : () => import('@/templates/views/recruitment/applications/dialogs/briefing/ApplicationScheduleInterviewCall.vue')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-sms',
                    label     : 'Send message',
                    condition : ( application, ctx ) =>
                    {
                        return $permission.isVisible( 'message-employer', 'application-list', application ) ||
                               $permission.isVisible( 'message-recruiter', 'application-list', application ) ||
                               $permission.isVisible( 'message-account-manager', 'application-list', application )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Job manager',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'message-employer', 'application-list', application ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/applications/${item.id}/create?currentTab=ApplicationMessages${item.employers.map( e => '&recipient=' + e.id ).join('')}`) },
                            }
                        },
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Recruiter',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'message-recruiter', 'application-list', application ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/applications/${item.id}/create?currentTab=ApplicationMessages&recipient=${item.recruiter.id}`) },
                            }
                        },
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Account manager',
                            condition : ( application, ctx ) => { return $permission.isVisible( 'message-account-manager', 'application-list', application ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/applications/${item.id}/create?currentTab=ApplicationMessages${item.accountManagers.map( e => '&recipient=' + e.id ).join('')}`) },
                            }
                        }
                    ]
                },

                {
                    type: 'divider',
                    condition : ( application, ctx ) =>
                    {
                        return $permission.isVisible( 'application-withdraw', 'application-list', { application, job: application.job } ) ||
                               $permission.isVisible( 'application-reject', 'application-list', { application, job: application.job } ) ||
                               $permission.isVisible( 'application-dropout', 'application-list', { application } )
                    },
                },

                {
                    icon      : 'isax-card-slash',
                    label     : 'Withdraw application',
                    condition : ( application, ctx ) => { return $permission.isVisible( 'application-withdraw', 'application-list', { application, job: application.job } ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has(['recruiter']) && ['open','paused'].includes( application.job.status ) && ["submitted","reviewed","interviewing","under-offer"].includes( application.status )
                    action    :
                    {
                        type      : 'modal',
                        id        : 'ApplicationWithdrawnModal',
                        ctx       : { fetchList: async () => await fetchItemList() },
                        transform : ( store, item ) => { return { id: item.id } },
                        component : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationWithdrawnDialog.vue')
                    }
                },
                {
                    icon      : 'isax-card-slash',
                    label     : 'Reject application',
                    condition : ( application, ctx ) => { return $permission.isVisible( 'application-reject', 'application-list', { application, job: application.job } ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has(['admin','employer']) && ['open','paused'].includes( application.job.status ) && ["submitted","reviewed","interviewing","under-offer"].includes( application.status )
                    action    :
                    {
                        type      : 'modal',
                        id        : 'ApplicationRejectedModal',
                        ctx       : { fetchList: async () => await fetchItemList() },
                        transform : ( store, item ) => { return { id: item.id } },
                        component : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationRejectedDialog.vue')
                    }
                },
                {
                    icon      : 'isax-card-slash',
                    label     : 'Dropout application',
                    condition : ( application, ctx ) => { return $permission.isVisible( 'application-dropout', 'application-list', { application } ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has(['admin','employer']) && ["placed","hired"].includes( application.status )
                    action    :
                    {
                        type      : 'modal',
                        id        : 'ApplicationDropoutModal',
                        ctx       : { fetchList: async () => await fetchItemList() },
                        transform : ( store, item ) => { return { id: item.id } },
                        component : () => import('@/templates/views/recruitment/applications/dialogs/progress/ApplicationDropoutDialog.vue')
                    }
                }
            ]
        };

        if( !sections.includes('header') ) { delete actionList.header; }
        if( !sections.includes('bulk') )   { delete actionList.bulk; }
        if( !sections.includes('quick') )  { delete actionList.quick; }

        return actionList;
    },

    'placementList': ( sections = [ 'header', 'bulk', 'quick' ], $permission, $router, $dialogs, $swagger, fetchItemList ) =>
    {
        let actionList =
        {
            header :
            [
                {
                    icon      : 'isax-add',
                    label     : 'Create placement',
                    class     : 'pulse-animation',
                    condition : ( placement, ctx ) => { return $permission.isVisible( 'placement-create', 'placement-list', placement ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has(['admin','employer'])
                    action    :
                    {
                        type      : 'modal',
                        id        : 'PlacementModal',
                        ctx       : { method: 'create', fetchList: async () => await fetchItemList() },
                        component : () => import('@/templates/views/recruitment/placements/dialogs/PlacementDialog')
                    }
                }
            ],
            bulk   : [],
            quick  :
            [
                {
                    icon      : 'isax-search-status',
                    label     : 'Details',
                    children  :
                    [
                        {
                            icon      : 'isax-eye',
                            label     : 'Show details',
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push( { name: 'PlacementDetail', params: { placementID: item.id } } ) },
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-edit-2',
                    label     : 'Modification',
                    condition : ( placement, ctx ) =>
                    {
                        return $permission.isVisible( 'placement-dropout', 'placement-list', placement ) ||
                               $permission.isVisible( 'placement-edit', 'placement-list', placement ) ||
                               $permission.isVisible( 'placement-duplicate', 'placement-list', placement )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-notification-status4',
                            label     : 'Change status',
                            condition : ( placement, ctx ) => { return $permission.isVisible( 'placement-dropout', 'placement-list', placement ) },
                            children  :
                            [
                                {
                                    icon      : 'isax-note-remove',
                                    label     : 'Dropout',
                                    condition : ( placement, ctx ) => { return $permission.isVisible( 'placement-dropout', 'placement-list', placement ) },
                                    action    :
                                    {
                                        type  : 'modal',
                                        id    : 'AreYouSureModal',
                                        props :
                                        {
                                            title          : 'Confirm placement dropout',
                                            description    : 'You are about to <b>dropout</b> this placement. <br/><br/> Dropping this placement is final and cannot be undone. Please confirm that you want to proceed with this action.',
                                            handleCallback : true,
                                            callback       : async ( submitButton ) =>
                                            {
                                                await $swagger.dropout_placement( $dialogs['AreYouSureModal'].ctx.id );

                                                submitButton.element.addEventListener('animationend', async () =>
                                                {
                                                    $dialogs['AreYouSureModal'].close();

                                                    await fetchItemList();

                                                }, { once: true });
                                            }
                                        },
                                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                                    }
                                }
                            ]
                        },
                        {
                            icon      : 'isax-edit',
                            label     : 'Edit',
                            condition : ( placement, ctx ) => { return $permission.isVisible( 'placement-edit', 'placement-list', placement ) }, //ctx.$permission.has(['admin','employer'])
                            action    :
                            {
                                type        : 'modal',
                                id          : 'PlacementModal',
                                ctx         : { method: 'edit', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/placements/dialogs/PlacementDialog')
                            }
                        },
                        {
                            icon      : 'isax-copy',
                            label     : 'Duplicate',
                            condition : ( placement, ctx ) => { return $permission.isVisible( 'placement-duplicate', 'placement-list', placement ) }, //ctx.$permission.has(['admin','employer'])
                            action    :
                            {
                                type        : 'modal',
                                id          : 'PlacementModal',
                                ctx         : { method: 'duplicate' },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/placements/dialogs/PlacementDialog')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-sms',
                    label     : 'Send message',
                    condition : ( placement, ctx ) =>
                    {
                        return $permission.isVisible( 'message-employer', 'placement-list', placement ) ||
                               $permission.isVisible( 'message-recruiter', 'placement-list', placement ) ||
                               $permission.isVisible( 'message-account-manager', 'placement-list', placement )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Job manager',
                            condition : ( placement, ctx ) => { return $permission.isVisible( 'message-employer', 'placement-list', placement ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/placements/${item.id}/create?currentTab=PlacementMessages${item.employer.map( e => '&recipient=' + e.id ).join('')}`) },
                            }
                        },
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Recruiter',
                            condition : ( placement, ctx ) => { return $permission.isVisible( 'message-recruiter', 'placement-list', placement ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/placements/${item.id}/create?currentTab=PlacementMessages&recipient=${item.recruiter.id}`) },
                            }
                        },
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Account manager',
                            condition : ( placement, ctx ) => { return $permission.isVisible( 'message-account-manager', 'placement-list', placement ) },
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/placements/${item.id}/create?currentTab=PlacementMessages${item.contacts.accountManagers.map( e => '&recipient=' + e.id ).join('')}`) },
                            }
                        }
                    ]
                },

                {
                    type: 'divider',
                    condition : ( placement, ctx ) => { return $permission.isVisible( 'placement-delete', 'placement-list', placement ) }
                },

                {
                    icon      : 'isax-trash',
                    label     : 'Delete placement',
                    condition : ( placement, ctx ) => { return $permission.isVisible( 'placement-delete', 'placement-list', placement ) },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'AreYouSureModal',
                        props       :
                        {
                            title          : 'Confirm placement deletion',
                            description    : 'You are about to <b>permanently delete</b> this placement. <br/><br/> Deleting this placement is final and cannot be undone. Please confirm that you want to proceed with this action.',
                            handleCallback : true,
                            callback       : async ( submitButton ) =>
                            {
                                await $swagger.delete_placement( $dialogs['AreYouSureModal'].ctx.id );

                                submitButton.element.addEventListener('animationend', async () =>
                                {
                                    $dialogs['AreYouSureModal'].close(); await fetchItemList();

                                }, { once: true });
                            }
                        },
                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                    }
                }
            ]
        }

        if( !sections.includes('header') ) { delete actionList.header; }
        if( !sections.includes('bulk') )   { delete actionList.bulk; }
        if( !sections.includes('quick') )  { delete actionList.quick; }

        return actionList;
    },

    'contractList': ( sections = [ 'header', 'bulk', 'quick' ], $permission, $router, $dialogs, $swagger, fetchItemList ) =>
    {
        let actionList =
        {
            header :
            [
                {
                    icon      : 'isax-add',
                    label     : 'Create contract',
                    class     : 'pulse-animation',
                    condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-create', 'contract-list', contract ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has(['admin','employer'])
                    action    :
                    {
                        type      : 'modal',
                        id        : 'ContractModal',
                        ctx       : { method: 'create', fetchList: async () => await fetchItemList() },
                        component : () => import('@/templates/views/recruitment/contracts/dialogs/ContractDialog')
                    }
                }
            ],
            bulk   : [],
            quick  :
            [
                {
                    icon      : 'isax-search-status',
                    label     : 'Details',
                    children  :
                    [
                        {
                            icon      : 'isax-eye',
                            label     : 'Show details',
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push( { name: 'ContractsList', params: { contractID: item.id } } ) },
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-edit-2',
                    label     : 'Modification',
                    condition : ( contract, ctx ) =>
                    {
                        return $permission.isVisible( 'contract-extend', 'contract-list', contract ) ||
                               $permission.isVisible( 'contract-terminate', 'contract-list', contract ) ||
                               $permission.isVisible( 'contract-dropout', 'contract-list', contract ) ||
                               $permission.isVisible( 'contract-timesheet-add', 'contract-list', contract ) ||
                               $permission.isVisible( 'contract-edit', 'contract-list', contract ) ||
                               $permission.isVisible( 'contract-duplicate', 'contract-list', contract )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-notification-status4',
                            label     : 'Change status',
                            condition : ( contract, ctx ) =>
                            {
                                return $permission.isVisible( 'contract-extend', 'contract-list', contract ) ||
                                       $permission.isVisible( 'contract-terminate', 'contract-list', contract ) ||
                                       $permission.isVisible( 'contract-dropout', 'contract-list', contract )
                            },
                            children  :
                            [
                                {
                                    icon      : 'isax-rotate-right',
                                    label     : 'Extend',
                                    condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-extend', 'contract-list', contract ) },
                                    action    :
                                    {
                                        type        : 'modal',
                                        id          : 'ContractExtendModal',
                                        ctx         : { fetchList: async () => await fetchItemList() },
                                        component   : () => import('@/templates/views/recruitment/contracts/dialogs/edit/ContractExtendDialog')
                                    }
                                },
                                {
                                    icon      : 'isax-stop-circle',
                                    label     : 'Terminate',
                                    condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-terminate', 'contract-list', contract ) },
                                    action    :
                                    {
                                        type        : 'modal',
                                        id          : 'AreYouSureModal',
                                        props       :
                                        {
                                            title          : 'Confirm contract termination',
                                            description    : 'You are about to <b>terminate</b> this contract. <br/><br/> Terminating this contract is final and cannot be undone. Please confirm that you want to proceed with this action.',
                                            handleCallback : true,
                                            callback       : async ( submitButton ) =>
                                            {
                                                await $swagger.terminate_contract( $dialogs['AreYouSureModal'].ctx.id );

                                                submitButton.element.addEventListener('animationend', async () => { $dialogs['AreYouSureModal'].close(); await fetchItemList(); }, { once: true });
                                            }
                                        },
                                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                                    }
                                },
                                {
                                    icon      : 'isax-note-remove',
                                    label     : 'Dropout',
                                    condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-dropout', 'contract-list', contract ) },
                                    action    :
                                    {
                                        type        : 'modal',
                                        id          : 'AreYouSureModal',
                                        props       :
                                        {
                                            title          : 'Confirm contract dropout',
                                            description    : 'You are about to <b>dropout</b> this contract. <br/><br/> Dropping this contract is final and cannot be undone. Please confirm that you want to proceed with this action.',
                                            handleCallback : true,
                                            callback       : async ( submitButton ) =>
                                            {
                                                await $swagger.dropout_contract( $dialogs['AreYouSureModal'].ctx.id );

                                                submitButton.element.addEventListener('animationend', async () =>
                                                {
                                                    $dialogs['AreYouSureModal'].close();

                                                    await fetchItemList();

                                                }, { once: true });
                                            }
                                        },
                                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                                    }
                                }
                            ]
                        },
                        {
                            icon      : 'isax-calendar-edit',
                            label     : 'Add timesheet',
                            condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-timesheet-add', 'contract-list', contract ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'TimesheetModal',
                                ctx         : { method: 'create', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { contractID: item.id } },
                                component   : () => import('@/templates/views/recruitment/contracts/dialogs/timesheet/TimesheetDialog.vue')
                            }
                        },
                        {
                            icon      : 'isax-edit',
                            label     : 'Edit',
                            condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-edit', 'contract-list', contract ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ContractModal',
                                ctx         : { method: 'edit', fetchList: async () => await fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/contracts/dialogs/ContractDialog')
                            }
                        },
                        {
                            icon      : 'isax-copy',
                            label     : 'Duplicate',
                            condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-duplicate', 'contract-list', contract ) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ContractModal',
                                ctx         : { method: 'duplicate' },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/contracts/dialogs/ContractDialog')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-sms',
                    label     : 'Send message',
                    condition : ( contract, ctx ) =>
                    {
                        return $permission.isVisible( 'message-employer', 'contract-list', contract ) ||
                               $permission.isVisible( 'message-recruiter', 'contract-list', contract ) ||
                               $permission.isVisible( 'message-account-manager', 'contract-list', contract )
                    },
                    children  :
                    [
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Job manager',
                            condition : ( contract, ctx ) => { return $permission.isVisible( 'message-employer', 'contract-list', contract ) }, //ctx.$permission.has(['admin','recruiter']) && contract?.employer?.length
                            action    :
                                {
                                    type   : 'function',
                                    method : ( item ) => { $router.push(`/contracts/${item.id}/create?currentTab=ContractMessages${item.employer.map( e => '&recipient=' + e.id ).join('')}`) },
                                }
                        },
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Recruiter',
                            condition : ( contract, ctx ) => { return $permission.isVisible( 'message-recruiter', 'contract-list', contract ) }, //ctx.$permission.has(['admin','employer']) && contract?.recruiter?.id
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/contracts/${item.id}/create?currentTab=ContractMessages&recipient=${item.recruiter.id}`) },
                            }
                        },
                        {
                            icon      : 'isax-sms-edit',
                            label     : 'Account manager',
                            condition : ( contract, ctx ) => { return $permission.isVisible( 'message-account-manager', 'contract-list', contract ) }, //ctx.$permission.has(['employer','recruiter']) && contract?.contacts?.accountManagers?.length
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push(`/contracts/${item.id}/create?currentTab=ContractMessages${item.contacts.accountManagers.map( e => '&recipient=' + e.id ).join('')}`) },
                            }
                        }
                    ]
                },

                {
                    type: 'divider',
                    condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-delete', 'contract-list', contract ) }
                },

                {
                    icon      : 'isax-trash',
                    label     : 'Delete contract',
                    condition : ( contract, ctx ) => { return $permission.isVisible( 'contract-delete', 'contract-list', contract ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has(['admin','employer'])
                    action    :
                    {
                        type  : 'modal',
                        id    : 'AreYouSureModal',
                        props :
                        {
                            title          : 'Confirm contract deletion',
                            description    : 'You are about to <b>permanently delete</b> this contract. <br/><br/> Deleting this contract is final and cannot be undone. Please confirm that you want to proceed with this action.',
                            handleCallback : true,
                            callback       : async ( submitButton ) =>
                            {
                                await $swagger.delete_contract( $dialogs['AreYouSureModal'].ctx.id );

                                submitButton.element.addEventListener('animationend', async () =>
                                {
                                    $dialogs['AreYouSureModal'].close(); await fetchItemList();

                                }, { once: true });
                            }
                        },
                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                    }
                }
            ]
        }

        if( !sections.includes('header') ) { delete actionList.header; }
        if( !sections.includes('bulk') )   { delete actionList.bulk; }
        if( !sections.includes('quick') )  { delete actionList.quick; }

        return actionList;
    },

    'agencyList': ( sections = [ 'header', 'bulk', 'quick' ], $store, $permission, $router, $dialogs, $swagger, company, fetchItemList ) =>
    {
        let actionList =
        {
            header :
            [
                {
                    icon      : 'isax-award',
                    label     : 'Agency suggester',
                    class     : 'primary',
                    condition : ( agency, ctx ) => { return $permission.isVisible( 'only-admin', 'global' ) },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'AgencySuggesterModal',
                        component   : () => import('@/templates/views/recruitment/agencies/dialogs/suggester/AgencySuggesterDialog.vue'),
                    }
                },

                {
                    icon      : 'isax-add4',
                    label     : 'Invite agency to register',
                    class     : 'primary',
                    condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-invite', 'global' ) },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'OnboardAgencyInvitationModal',
                        component   : () => import('@/templates/views/recruitment/agencies/dialogs/invitation/OnboardAgencyInvitationDialog.vue'),
                    }
                }
            ],
            bulk   :
            [
                {
                    icon      : 'isax-hashtag',
                    label     : 'Tags',
                    class     : 'primary',
                    condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-tag', 'agency-list' ) },
                    action    :
                    {
                        type: 'tag',
                        condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-tag', 'agency-list' ) },
                    }
                },
            ],
            quick  :
            [
                {
                    icon      : 'isax-search-status',
                    label     : 'Details',
                    children  :
                    [
                        {
                            icon      : 'isax-eye',
                            label     : 'Show details',
                            action    :
                            {
                                type   : 'function',
                                method : ( item ) => { $router.push( { name: 'AgencyDetail', params: { agencyID: item.id } } ) },
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-edit-2',
                    label     : 'Actions',
                    condition : ( agency, ctx ) => { $permission.isVisible( 'agency-exclude', 'agency-list', { agency, company } ) || $permission.isVisible( 'agency-exclude-remove', 'agency-list', { agency, company } ) },
                    children  :
                    [
                        {
                            icon      : 'isax-eye-slash',
                            label     : 'Exclude agency',
                            condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-exclude', 'agency-list', { agency, company } ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has([ 'admin', 'employer' ]) && !company.value?.blacklist?.agencies?.find( a => a.id === item.id )
                            action    :
                            {
                                type        : 'modal',
                                id          : 'AreYouSureModal',
                                props       :
                                {
                                    title          : 'Confirm exclusion addition',
                                    description    : 'You are about to <b>add</b> this agency to the exclusion list.<br/><br/> Once added, they will be restricted from receiving invitations to engage in current or future job opportunities.',
                                    buttonClass    : 'primary md error',
                                    handleCallback : true,
                                    callback       : async ( submitButton ) =>
                                    {
                                        await $swagger.update_organization( company.value.id, { body: { blacklist: { agencyIDs: [ ...new Set([ ...( company.value?.blacklist?.agencies?.map( a => a.id ) || [] ), $dialogs['AreYouSureModal'].ctx.id ]) ] } } });

                                        submitButton.element.addEventListener('animationend', async () =>
                                        {
                                            $dialogs['AreYouSureModal'].close();

                                            company.value = await $swagger.get_organization( $store.getters['auth/user/detail'].currentAccount.organization.id, { query: { raw: true } } );

                                            await fetchItemList();

                                        }, { once: true });
                                    }
                                },
                                component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                            }
                        },

                        {
                            type: 'divider',
                            condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-exclude-remove', 'agency-list', { agency, company } ) },
                        },

                        {
                            icon      : 'isax-trash',
                            label     : 'Remove Exclusion',
                            condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-exclude-remove', 'agency-list', { agency, company } ) }, //!ctx.$permission.isRampAccount() && ctx.$permission.has([ 'admin', 'employer' ]) && company.value?.blacklist?.agencies?.find( a => a.id === item.id )
                            action    :
                            {
                                type        : 'modal',
                                id          : 'AreYouSureModal',
                                props       :
                                {
                                    title          : 'Confirm exclusion removal',
                                    description    : 'You are about to <b>remove</b> agency from the exclusion list.<br/><br/> Once removed, you will be able to invite them to engage in current or future job opportunities.',
                                    buttonClass    : 'primary md success',
                                    handleCallback : true,
                                    callback       : async ( submitButton ) =>
                                    {
                                        let blacklist = { agencyIDs: [] };

                                        if( company.value?.hasOwnProperty('blacklist') && company.value.blacklist.agencies?.length )
                                        {
                                            blacklist = { agencyIDs: company.value.blacklist.agencies.filter( f => f.id !== $dialogs['AreYouSureModal'].ctx.id ).map( i => i.id ) };
                                        }

                                        await $swagger.update_organization( company.value.id, { body: { blacklist } });

                                        submitButton.element.addEventListener('animationend', async () =>
                                        {
                                            $dialogs['AreYouSureModal'].close();

                                            company.value = await $swagger.get_organization( $store.getters['auth/user/detail'].currentAccount.organization.id, { query: { raw: true } } );

                                            await fetchItemList();

                                        }, { once: true });
                                    }
                                },
                                component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                            }
                        }
                    ]
                },

                {
                    icon      : 'isax-hashtag',
                    label     : 'Tags',
                    condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-tag', 'agency-list' ) },
                    children  :
                    [
                        {
                            type: 'tag',
                            condition : ( agency, ctx ) => { return $permission.isVisible( 'agency-tag', 'agency-list' ) },
                        }
                    ]
                }
            ]
        }

        if( !sections.includes('header') ) { delete actionList.header; }
        if( !sections.includes('bulk') )   { delete actionList.bulk; }
        if( !sections.includes('quick') )  { delete actionList.quick; }

        return actionList;
    },

    'programmeList': ( sections = [ 'header', 'bulk', 'quick' ], $permission, $router, $dialogs, $swagger, fetchItemList ) =>
    {
        let actionList =
        {
            header:
            [
                /*
                {
                    icon      : 'isax-add',
                    label     : 'Create program',
                    condition : ( programme, ctx ) => { return !ctx.$permission.isRampAccount() && ctx.$permission.has(['admin']) },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'ProgrammeModal',
                        ctx         : { method: 'create', fetchList: async () => await $_helpers.fetchItemList() },
                        component   : () => import('@/templates/views/recruitment/programmes/dialogs/ProgrammeDialog')
                    }
                }
                */
            ],
            quick:
            [
                /*
                {
                    icon      : 'isax-edit-2',
                    label     : 'Modification',
                    condition : ( job, ctx ) => { return !ctx.$permission.isRampAccount() && ctx.$permission.has(['admin']) },
                    children  :
                    [
                        {
                            icon      : 'isax-edit',
                            label     : 'Edit programme',
                            condition : ( programme, ctx ) => { return ctx.$permission.has(['admin']) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'ProgrammeModal',
                                ctx         : { method: 'edit', fetchList: async () => await $_helpers.fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/programmes/dialogs/ProgrammeDialog')
                            }
                        },
                    ]
                },

                {
                    icon      : 'isax-next',
                    label     : 'Progression',
                    condition : ( job, ctx ) => { return !ctx.$permission.isRampAccount() },
                    children  :
                    [
                        {
                            icon      : 'isax-cloud-add4',
                            label     : 'Invite agencies',
                            class     : 'primary',
                            condition : ( programme, ctx ) => { return !ctx.$permission.isRampAccount() && ctx.$permission.has(['admin']) },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'AgencyInvitationModal',
                                ctx         : { fetchList: async () => await $_helpers.fetchItemList() },
                                transform   : ( store, item ) => { return { id: item.id } },
                                component   : () => import('@/templates/views/recruitment/programmes/dialogs/invitation/ProgrammeAgencyInvitationDialog.vue')
                            }
                        },
                        {
                            icon      : 'isax-archive-tick',
                            label     : 'Accept invitation',
                            condition : ( programme, ctx ) => { return ctx.$permission.has(['recruiter']) && programme.pendingInvitation },
                            action    :
                            {
                                type        : 'modal',
                                id          : 'AreYouSureModal',
                                props       :
                                    {
                                        title          : 'Confirm Acceptance of Invitation',
                                        description    : 'You are about to <b>accept</b> the invitation from this employer.<br/><br/> Once accepted, you’ll gain access to their program. Are you sure you want to proceed?',
                                        buttonClass    : 'primary md success',
                                        handleCallback : true,
                                        callback       : async ( submitButton ) =>
                                        {
                                            await $swagger.accept_onboarding_invitation( $dialogs['AreYouSureModal'].ctx.pendingInvitation );

                                            submitButton.element.addEventListener('animationend', async () =>
                                            {
                                                $dialogs['AreYouSureModal'].close(); await $_helpers.fetchItemList();

                                            }, { once: true });
                                        }
                                    },
                                component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                            }
                        }
                    ]
                },

                {
                    type: 'divider',
                    condition : ( job, ctx ) => { return !ctx.$permission.isRampAccount() && ctx.$permission.has(['recruiter']) && programme.pendingInvitation },
                },

                {
                    icon      : 'isax-archive-slash',
                    label     : 'Decline invitation',
                    condition : ( programme, ctx ) => { return !ctx.$permission.isRampAccount() && ctx.$permission.has(['recruiter']) && programme.pendingInvitation },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'AreYouSureModal',
                        props       :
                        {
                            title          : 'Confirm Decline of Invitation',
                            description    : 'You are about to <b>decline</b> the invitation from this employer.<br/><br/> This action cannot be undone, and you will not have access to their program. Are you certain you want to decline?',
                            buttonClass    : 'primary md error',
                            handleCallback : true,
                            callback       : async ( submitButton ) =>
                            {
                                await $swagger.reject_onboarding_invitation( $dialogs['AreYouSureModal'].ctx.pendingInvitation );

                                submitButton.element.addEventListener('animationend', async () =>
                                {
                                    $dialogs['AreYouSureModal'].close(); await $_helpers.fetchItemList();

                                }, { once: true });
                            }
                        },
                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                    }
                }
                */
            ]
        }

        if( !sections.includes('header') ) { delete actionList.header; }
        if( !sections.includes('bulk') )   { delete actionList.bulk; }
        if( !sections.includes('quick') )  { delete actionList.quick; }

        return actionList;
    },

    'agencyOnboardingList': ( sections = [ 'header', 'bulk', 'quick' ], $permission, $router, $dialogs, $swagger, fetchItemList ) =>
    {
        let actionList =
        {
            header : [],
            quick  :
            [
                {
                    icon      : 'isax-archive-tick',
                    label     : 'Accept invitation',
                    condition : ( onboarding, ctx ) => { return $permission.isVisible( 'agency-onboarding-invitation-accept', 'agency-list', { onboarding }); },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'AreYouSureModal',
                        props       :
                        {
                            title          : 'Confirm acceptance of invitation',
                            description    : 'You are about to <b>accept</b> the invitation from this employer.<br/><br/> Once accepted, you’ll gain access to their <b>job requisitions</b>. <br/><br/>Are you sure you want to proceed?',
                            buttonClass    : 'primary md success',
                            handleCallback : true,
                            callback       : async ( submitButton ) =>
                            {
                                await $swagger.accept_onboarding_invitation( $dialogs['AreYouSureModal'].ctx.id );

                                submitButton.element.addEventListener('animationend', async () =>
                                {
                                    $dialogs['AreYouSureModal'].close(); await fetchItemList();

                                }, { once: true });
                            }
                        },
                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                    }
                },
                {
                    icon      : 'isax-archive-slash',
                    label     : 'Decline invitation',
                    condition : ( onboarding, ctx ) => { return $permission.isVisible( 'agency-onboarding-invitation-decline', 'agency-list', { onboarding }); },
                    action    :
                    {
                        type        : 'modal',
                        id          : 'AreYouSureModal',
                        props       :
                        {
                            title          : 'Confirm decline of invitation',
                            description    : 'You are about to <b>decline</b> the invitation from this employer.<br/><br/> This action cannot be undone, and you will not have access to their <b>job requisitions</b>.<br/><br/> Are you certain you want to decline?',
                            buttonClass    : 'primary md error',
                            handleCallback : true,
                            callback       : async ( submitButton ) =>
                            {
                                await $swagger.reject_onboarding_invitation( $dialogs['AreYouSureModal'].ctx.id );

                                submitButton.element.addEventListener('animationend', async () =>
                                {
                                    $dialogs['AreYouSureModal'].close(); await fetchItemList();

                                }, { once: true });
                            }
                        },
                        component: () => import('@/templates/components/Dialogs/AreYouSureDialog')
                    }
                },
            ]
        }

        if( !sections.includes('header') ) { delete actionList.header; }
        if( !sections.includes('bulk') )   { delete actionList.bulk; }
        if( !sections.includes('quick') )  { delete actionList.quick; }

        return actionList;
    }
}

export const Table =
{
    createObject( listName, options, storagePath )
    {
        if( $_TableObject.hasOwnProperty( listName ) )
        {
            //let $_cloneOptions = JSON.parse( JSON.stringify( $_TableObject[ listName ] ) );

            let $_storage      = JSON.parse( Helpers?.Storage( undefined, 'get', storagePath ) || '{}' );
            let $_cloneOptions = ObjectLib.clone( true, {}, $_TableObject[ listName ] );

            if( Object.keys( options ).length )
            {
                options.hasOwnProperty('grid')  && Object.assign( $_cloneOptions.grid, options.grid );
                options.hasOwnProperty('table') && Object.assign( $_cloneOptions.table, options.table );
            }

            $_cloneOptions.table.thead = reactive( $_cloneOptions.table.columns );

            if( $_storage?.customFields?.length )
            {
                for( let field of $_storage?.customFields )
                {
                    for( let column of field?.export?.columns )
                    {
                        column.data = ( item, ctx ) => { return item?.metadata?.[ field.id ] };
                    }

                    $_cloneOptions.table.thead.push( field );
                }
            }

            delete $_cloneOptions.table.columns;
            delete $_cloneOptions.table.presets;

            return $_cloneOptions;
        }
        else
        {
            console.error( `TABLE:createObject => Table object with name '${listName}' does not exist!` );
        }
    },

    defaultPreset()
    {
        return '{}'
    }
}